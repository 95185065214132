import R from "jquery";
import q from "underscore";
+function(t) {
  function o() {
    var D = document.createElement("bootstrap"), k = {
      WebkitTransition: "webkitTransitionEnd",
      MozTransition: "transitionend",
      OTransition: "oTransitionEnd otransitionend",
      transition: "transitionend"
    };
    for (var c in k)
      if (D.style[c] !== void 0)
        return { end: k[c] };
    return !1;
  }
  t.fn.emulateTransitionEnd = function(D) {
    var k = !1, c = this;
    t(this).one("bsTransitionEnd", function() {
      k = !0;
    });
    var n = function() {
      k || t(c).trigger(t.support.transition.end);
    };
    return setTimeout(n, D), this;
  }, t(function() {
    t.support.transition = o(), t.support.transition && (t.event.special.bsTransitionEnd = {
      bindType: t.support.transition.end,
      delegateType: t.support.transition.end,
      handle: function(D) {
        if (t(D.target).is(this)) return D.handleObj.handler.apply(this, arguments);
      }
    });
  });
}(R);
+function(t) {
  var o = '[data-dismiss="alert"]', D = function(n) {
    t(n).on("click", o, this.close);
  };
  D.VERSION = "3.4.1", D.TRANSITION_DURATION = 150, D.prototype.close = function(n) {
    var a = t(this), r = a.attr("data-target");
    r || (r = a.attr("href"), r = r && r.replace(/.*(?=#[^\s]*$)/, "")), r = r === "#" ? [] : r;
    var g = t(document).find(r);
    if (n && n.preventDefault(), g.length || (g = a.closest(".alert")), g.trigger(n = t.Event("close.bs.alert")), n.isDefaultPrevented()) return;
    g.removeClass("in");
    function m() {
      g.detach().trigger("closed.bs.alert").remove();
    }
    t.support.transition && g.hasClass("fade") ? g.one("bsTransitionEnd", m).emulateTransitionEnd(D.TRANSITION_DURATION) : m();
  };
  function k(n) {
    return this.each(function() {
      var a = t(this), r = a.data("bs.alert");
      r || a.data("bs.alert", r = new D(this)), typeof n == "string" && r[n].call(a);
    });
  }
  var c = t.fn.alert;
  t.fn.alert = k, t.fn.alert.Constructor = D, t.fn.alert.noConflict = function() {
    return t.fn.alert = c, this;
  }, t(document).on("click.bs.alert.data-api", o, D.prototype.close);
}(R);
+function(t) {
  var o = function(c, n) {
    this.$element = t(c), this.options = t.extend({}, o.DEFAULTS, n), this.isLoading = !1;
  };
  o.VERSION = "3.4.1", o.DEFAULTS = {
    loadingText: "loading..."
  }, o.prototype.setState = function(c) {
    var n = "disabled", a = this.$element, r = a.is("input") ? "val" : "html", g = a.data();
    c += "Text", g.resetText == null && a.data("resetText", a[r]()), setTimeout(t.proxy(function() {
      a[r](g[c] == null ? this.options[c] : g[c]), c == "loadingText" ? (this.isLoading = !0, a.addClass(n).attr(n, n).prop(n, !0)) : this.isLoading && (this.isLoading = !1, a.removeClass(n).removeAttr(n).prop(n, !1));
    }, this), 0);
  }, o.prototype.toggle = function() {
    var c = !0, n = this.$element.closest('[data-toggle="buttons"]');
    if (n.length) {
      var a = this.$element.find("input");
      a.prop("type") == "radio" ? (a.prop("checked") && (c = !1), n.find(".active").removeClass("active"), this.$element.addClass("active")) : a.prop("type") == "checkbox" && (a.prop("checked") !== this.$element.hasClass("active") && (c = !1), this.$element.toggleClass("active")), a.prop("checked", this.$element.hasClass("active")), c && a.trigger("change");
    } else
      this.$element.attr("aria-pressed", !this.$element.hasClass("active")), this.$element.toggleClass("active");
  };
  function D(c) {
    return this.each(function() {
      var n = t(this), a = n.data("bs.button"), r = typeof c == "object" && c;
      a || n.data("bs.button", a = new o(this, r)), c == "toggle" ? a.toggle() : c && a.setState(c);
    });
  }
  var k = t.fn.button;
  t.fn.button = D, t.fn.button.Constructor = o, t.fn.button.noConflict = function() {
    return t.fn.button = k, this;
  }, t(document).on("click.bs.button.data-api", '[data-toggle^="button"]', function(c) {
    var n = t(c.target).closest(".btn");
    D.call(n, "toggle"), t(c.target).is('input[type="radio"], input[type="checkbox"]') || (c.preventDefault(), n.is("input,button") ? n.trigger("focus") : n.find("input:visible,button:visible").first().trigger("focus"));
  }).on("focus.bs.button.data-api blur.bs.button.data-api", '[data-toggle^="button"]', function(c) {
    t(c.target).closest(".btn").toggleClass("focus", /^focus(in)?$/.test(c.type));
  });
}(R);
+function(t) {
  var o = function(n, a) {
    this.$element = t(n), this.$indicators = this.$element.find(".carousel-indicators"), this.options = a, this.paused = null, this.sliding = null, this.interval = null, this.$active = null, this.$items = null, this.options.keyboard && this.$element.on("keydown.bs.carousel", t.proxy(this.keydown, this)), this.options.pause == "hover" && !("ontouchstart" in document.documentElement) && this.$element.on("mouseenter.bs.carousel", t.proxy(this.pause, this)).on("mouseleave.bs.carousel", t.proxy(this.cycle, this));
  };
  o.VERSION = "3.4.1", o.TRANSITION_DURATION = 600, o.DEFAULTS = {
    interval: 5e3,
    pause: "hover",
    wrap: !0,
    keyboard: !0
  }, o.prototype.keydown = function(n) {
    if (!/input|textarea/i.test(n.target.tagName)) {
      switch (n.which) {
        case 37:
          this.prev();
          break;
        case 39:
          this.next();
          break;
        default:
          return;
      }
      n.preventDefault();
    }
  }, o.prototype.cycle = function(n) {
    return n || (this.paused = !1), this.interval && clearInterval(this.interval), this.options.interval && !this.paused && (this.interval = setInterval(t.proxy(this.next, this), this.options.interval)), this;
  }, o.prototype.getItemIndex = function(n) {
    return this.$items = n.parent().children(".item"), this.$items.index(n || this.$active);
  }, o.prototype.getItemForDirection = function(n, a) {
    var r = this.getItemIndex(a), g = n == "prev" && r === 0 || n == "next" && r == this.$items.length - 1;
    if (g && !this.options.wrap) return a;
    var m = n == "prev" ? -1 : 1, b = (r + m) % this.$items.length;
    return this.$items.eq(b);
  }, o.prototype.to = function(n) {
    var a = this, r = this.getItemIndex(this.$active = this.$element.find(".item.active"));
    if (!(n > this.$items.length - 1 || n < 0))
      return this.sliding ? this.$element.one("slid.bs.carousel", function() {
        a.to(n);
      }) : r == n ? this.pause().cycle() : this.slide(n > r ? "next" : "prev", this.$items.eq(n));
  }, o.prototype.pause = function(n) {
    return n || (this.paused = !0), this.$element.find(".next, .prev").length && t.support.transition && (this.$element.trigger(t.support.transition.end), this.cycle(!0)), this.interval = clearInterval(this.interval), this;
  }, o.prototype.next = function() {
    if (!this.sliding)
      return this.slide("next");
  }, o.prototype.prev = function() {
    if (!this.sliding)
      return this.slide("prev");
  }, o.prototype.slide = function(n, a) {
    var r = this.$element.find(".item.active"), g = a || this.getItemForDirection(n, r), m = this.interval, b = n == "next" ? "left" : "right", w = this;
    if (g.hasClass("active")) return this.sliding = !1;
    var h = g[0], u = t.Event("slide.bs.carousel", {
      relatedTarget: h,
      direction: b
    });
    if (this.$element.trigger(u), !u.isDefaultPrevented()) {
      if (this.sliding = !0, m && this.pause(), this.$indicators.length) {
        this.$indicators.find(".active").removeClass("active");
        var d = t(this.$indicators.children()[this.getItemIndex(g)]);
        d && d.addClass("active");
      }
      var f = t.Event("slid.bs.carousel", { relatedTarget: h, direction: b });
      return t.support.transition && this.$element.hasClass("slide") ? (g.addClass(n), typeof g == "object" && g.length && g[0].offsetWidth, r.addClass(b), g.addClass(b), r.one("bsTransitionEnd", function() {
        g.removeClass([n, b].join(" ")).addClass("active"), r.removeClass(["active", b].join(" ")), w.sliding = !1, setTimeout(function() {
          w.$element.trigger(f);
        }, 0);
      }).emulateTransitionEnd(o.TRANSITION_DURATION)) : (r.removeClass("active"), g.addClass("active"), this.sliding = !1, this.$element.trigger(f)), m && this.cycle(), this;
    }
  };
  function D(n) {
    return this.each(function() {
      var a = t(this), r = a.data("bs.carousel"), g = t.extend({}, o.DEFAULTS, a.data(), typeof n == "object" && n), m = typeof n == "string" ? n : g.slide;
      r || a.data("bs.carousel", r = new o(this, g)), typeof n == "number" ? r.to(n) : m ? r[m]() : g.interval && r.pause().cycle();
    });
  }
  var k = t.fn.carousel;
  t.fn.carousel = D, t.fn.carousel.Constructor = o, t.fn.carousel.noConflict = function() {
    return t.fn.carousel = k, this;
  };
  var c = function(n) {
    var a = t(this), r = a.attr("href");
    r && (r = r.replace(/.*(?=#[^\s]+$)/, ""));
    var g = a.attr("data-target") || r, m = t(document).find(g);
    if (m.hasClass("carousel")) {
      var b = t.extend({}, m.data(), a.data()), w = a.attr("data-slide-to");
      w && (b.interval = !1), D.call(m, b), w && m.data("bs.carousel").to(w), n.preventDefault();
    }
  };
  t(document).on("click.bs.carousel.data-api", "[data-slide]", c).on("click.bs.carousel.data-api", "[data-slide-to]", c), t(window).on("load", function() {
    t('[data-ride="carousel"]').each(function() {
      var n = t(this);
      D.call(n, n.data());
    });
  });
}(R);
+function(t) {
  var o = function(n, a) {
    this.$element = t(n), this.options = t.extend({}, o.DEFAULTS, a), this.$trigger = t('[data-toggle="collapse"][href="#' + n.id + '"],[data-toggle="collapse"][data-target="#' + n.id + '"]'), this.transitioning = null, this.options.parent ? this.$parent = this.getParent() : this.addAriaAndCollapsedClass(this.$element, this.$trigger), this.options.toggle && this.toggle();
  };
  o.VERSION = "3.4.1", o.TRANSITION_DURATION = 350, o.DEFAULTS = {
    toggle: !0
  }, o.prototype.dimension = function() {
    var n = this.$element.hasClass("width");
    return n ? "width" : "height";
  }, o.prototype.show = function() {
    if (!(this.transitioning || this.$element.hasClass("in"))) {
      var n, a = this.$parent && this.$parent.children(".panel").children(".in, .collapsing");
      if (!(a && a.length && (n = a.data("bs.collapse"), n && n.transitioning))) {
        var r = t.Event("show.bs.collapse");
        if (this.$element.trigger(r), !r.isDefaultPrevented()) {
          a && a.length && (k.call(a, "hide"), n || a.data("bs.collapse", null));
          var g = this.dimension();
          this.$element.removeClass("collapse").addClass("collapsing")[g](0).attr("aria-expanded", !0), this.$trigger.removeClass("collapsed").attr("aria-expanded", !0), this.transitioning = 1;
          var m = function() {
            this.$element.removeClass("collapsing").addClass("collapse in")[g](""), this.transitioning = 0, this.$element.trigger("shown.bs.collapse");
          };
          if (!t.support.transition) return m.call(this);
          var b = t.camelCase(["scroll", g].join("-"));
          this.$element.one("bsTransitionEnd", t.proxy(m, this)).emulateTransitionEnd(o.TRANSITION_DURATION)[g](this.$element[0][b]);
        }
      }
    }
  }, o.prototype.hide = function() {
    if (!(this.transitioning || !this.$element.hasClass("in"))) {
      var n = t.Event("hide.bs.collapse");
      if (this.$element.trigger(n), !n.isDefaultPrevented()) {
        var a = this.dimension();
        this.$element[a](this.$element[a]())[0].offsetHeight, this.$element.addClass("collapsing").removeClass("collapse in").attr("aria-expanded", !1), this.$trigger.addClass("collapsed").attr("aria-expanded", !1), this.transitioning = 1;
        var r = function() {
          this.transitioning = 0, this.$element.removeClass("collapsing").addClass("collapse").trigger("hidden.bs.collapse");
        };
        if (!t.support.transition) return r.call(this);
        this.$element[a](0).one("bsTransitionEnd", t.proxy(r, this)).emulateTransitionEnd(o.TRANSITION_DURATION);
      }
    }
  }, o.prototype.toggle = function() {
    this[this.$element.hasClass("in") ? "hide" : "show"]();
  }, o.prototype.getParent = function() {
    return t(document).find(this.options.parent).find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]').each(t.proxy(function(n, a) {
      var r = t(a);
      this.addAriaAndCollapsedClass(D(r), r);
    }, this)).end();
  }, o.prototype.addAriaAndCollapsedClass = function(n, a) {
    var r = n.hasClass("in");
    n.attr("aria-expanded", r), a.toggleClass("collapsed", !r).attr("aria-expanded", r);
  };
  function D(n) {
    var a, r = n.attr("data-target") || (a = n.attr("href")) && a.replace(/.*(?=#[^\s]+$)/, "");
    return t(document).find(r);
  }
  function k(n) {
    return this.each(function() {
      var a = t(this), r = a.data("bs.collapse"), g = t.extend({}, o.DEFAULTS, a.data(), typeof n == "object" && n);
      !r && g.toggle && /show|hide/.test(n) && (g.toggle = !1), r || a.data("bs.collapse", r = new o(this, g)), typeof n == "string" && r[n]();
    });
  }
  var c = t.fn.collapse;
  t.fn.collapse = k, t.fn.collapse.Constructor = o, t.fn.collapse.noConflict = function() {
    return t.fn.collapse = c, this;
  }, t(document).on("click.bs.collapse.data-api", '[data-toggle="collapse"]', function(n) {
    var a = t(this);
    a.attr("data-target") || n.preventDefault();
    var r = D(a), g = r.data("bs.collapse"), m = g ? "toggle" : a.data();
    k.call(r, m);
  });
}(R);
+function(t) {
  var o = function(c, n) {
    this.options = n, this.$body = t(document.body), this.$element = t(c), this.$dialog = this.$element.find(".modal-dialog"), this.$backdrop = null, this.isShown = null, this.originalBodyPad = null, this.scrollbarWidth = 0, this.ignoreBackdropClick = !1, this.fixedContent = ".navbar-fixed-top, .navbar-fixed-bottom", this.options.remote && this.$element.find(".modal-content").load(this.options.remote, t.proxy(function() {
      this.$element.trigger("loaded.bs.modal");
    }, this));
  };
  o.VERSION = "3.4.1", o.TRANSITION_DURATION = 300, o.BACKDROP_TRANSITION_DURATION = 150, o.DEFAULTS = {
    backdrop: !0,
    keyboard: !0,
    show: !0
  }, o.prototype.toggle = function(c) {
    return this.isShown ? this.hide() : this.show(c);
  }, o.prototype.show = function(c) {
    var n = this, a = t.Event("show.bs.modal", { relatedTarget: c });
    this.$element.trigger(a), !(this.isShown || a.isDefaultPrevented()) && (this.isShown = !0, this.checkScrollbar(), this.setScrollbar(), this.$body.addClass("modal-open"), this.escape(), this.resize(), this.$element.on("click.dismiss.bs.modal", '[data-dismiss="modal"]', t.proxy(this.hide, this)), this.$dialog.on("mousedown.dismiss.bs.modal", function() {
      n.$element.one("mouseup.dismiss.bs.modal", function(r) {
        t(r.target).is(n.$element) && (n.ignoreBackdropClick = !0);
      });
    }), this.backdrop(function() {
      var r = t.support.transition && n.$element.hasClass("fade");
      n.$element.parent().length || n.$element.appendTo(n.$body), n.$element.show().scrollTop(0), n.adjustDialog(), r && n.$element[0].offsetWidth, n.$element.addClass("in"), n.enforceFocus();
      var g = t.Event("shown.bs.modal", { relatedTarget: c });
      r ? n.$dialog.one("bsTransitionEnd", function() {
        n.$element.trigger("focus").trigger(g);
      }).emulateTransitionEnd(o.TRANSITION_DURATION) : n.$element.trigger("focus").trigger(g);
    }));
  }, o.prototype.hide = function(c) {
    c && c.preventDefault(), c = t.Event("hide.bs.modal"), this.$element.trigger(c), !(!this.isShown || c.isDefaultPrevented()) && (this.isShown = !1, this.escape(), this.resize(), t(document).off("focusin.bs.modal"), this.$element.removeClass("in").off("click.dismiss.bs.modal").off("mouseup.dismiss.bs.modal"), this.$dialog.off("mousedown.dismiss.bs.modal"), t.support.transition && this.$element.hasClass("fade") ? this.$element.one("bsTransitionEnd", t.proxy(this.hideModal, this)).emulateTransitionEnd(o.TRANSITION_DURATION) : this.hideModal());
  }, o.prototype.enforceFocus = function() {
    t(document).off("focusin.bs.modal").on("focusin.bs.modal", t.proxy(function(c) {
      document !== c.target && this.$element[0] !== c.target && !this.$element.has(c.target).length && this.$element.trigger("focus");
    }, this));
  }, o.prototype.escape = function() {
    this.isShown && this.options.keyboard ? this.$element.on("keydown.dismiss.bs.modal", t.proxy(function(c) {
      c.which == 27 && this.hide();
    }, this)) : this.isShown || this.$element.off("keydown.dismiss.bs.modal");
  }, o.prototype.resize = function() {
    this.isShown ? t(window).on("resize.bs.modal", t.proxy(this.handleUpdate, this)) : t(window).off("resize.bs.modal");
  }, o.prototype.hideModal = function() {
    var c = this;
    this.$element.hide(), this.backdrop(function() {
      c.$body.removeClass("modal-open"), c.resetAdjustments(), c.resetScrollbar(), c.$element.trigger("hidden.bs.modal");
    });
  }, o.prototype.removeBackdrop = function() {
    this.$backdrop && this.$backdrop.remove(), this.$backdrop = null;
  }, o.prototype.backdrop = function(c) {
    var n = this, a = this.$element.hasClass("fade") ? "fade" : "";
    if (this.isShown && this.options.backdrop) {
      var r = t.support.transition && a;
      if (this.$backdrop = t(document.createElement("div")).addClass("modal-backdrop " + a).appendTo(this.$body), this.$element.on("click.dismiss.bs.modal", t.proxy(function(m) {
        if (this.ignoreBackdropClick) {
          this.ignoreBackdropClick = !1;
          return;
        }
        m.target === m.currentTarget && (this.options.backdrop == "static" ? this.$element[0].focus() : this.hide());
      }, this)), r && this.$backdrop[0].offsetWidth, this.$backdrop.addClass("in"), !c) return;
      r ? this.$backdrop.one("bsTransitionEnd", c).emulateTransitionEnd(o.BACKDROP_TRANSITION_DURATION) : c();
    } else if (!this.isShown && this.$backdrop) {
      this.$backdrop.removeClass("in");
      var g = function() {
        n.removeBackdrop(), c && c();
      };
      t.support.transition && this.$element.hasClass("fade") ? this.$backdrop.one("bsTransitionEnd", g).emulateTransitionEnd(o.BACKDROP_TRANSITION_DURATION) : g();
    } else c && c();
  }, o.prototype.handleUpdate = function() {
    this.adjustDialog();
  }, o.prototype.adjustDialog = function() {
    var c = this.$element[0].scrollHeight > document.documentElement.clientHeight;
    this.$element.css({
      paddingLeft: !this.bodyIsOverflowing && c ? this.scrollbarWidth : "",
      paddingRight: this.bodyIsOverflowing && !c ? this.scrollbarWidth : ""
    });
  }, o.prototype.resetAdjustments = function() {
    this.$element.css({
      paddingLeft: "",
      paddingRight: ""
    });
  }, o.prototype.checkScrollbar = function() {
    var c = window.innerWidth;
    if (!c) {
      var n = document.documentElement.getBoundingClientRect();
      c = n.right - Math.abs(n.left);
    }
    this.bodyIsOverflowing = document.body.clientWidth < c, this.scrollbarWidth = this.measureScrollbar();
  }, o.prototype.setScrollbar = function() {
    var c = parseInt(this.$body.css("padding-right") || 0, 10);
    this.originalBodyPad = document.body.style.paddingRight || "";
    var n = this.scrollbarWidth;
    this.bodyIsOverflowing && (this.$body.css("padding-right", c + n), t(this.fixedContent).each(function(a, r) {
      var g = r.style.paddingRight, m = t(r).css("padding-right");
      t(r).data("padding-right", g).css("padding-right", parseFloat(m) + n + "px");
    }));
  }, o.prototype.resetScrollbar = function() {
    this.$body.css("padding-right", this.originalBodyPad), t(this.fixedContent).each(function(c, n) {
      var a = t(n).data("padding-right");
      t(n).removeData("padding-right"), n.style.paddingRight = a || "";
    });
  }, o.prototype.measureScrollbar = function() {
    var c = document.createElement("div");
    c.className = "modal-scrollbar-measure", this.$body.append(c);
    var n = c.offsetWidth - c.clientWidth;
    return this.$body[0].removeChild(c), n;
  };
  function D(c, n) {
    return this.each(function() {
      var a = t(this), r = a.data("bs.modal"), g = t.extend({}, o.DEFAULTS, a.data(), typeof c == "object" && c);
      r || a.data("bs.modal", r = new o(this, g)), typeof c == "string" ? r[c](n) : g.show && r.show(n);
    });
  }
  var k = t.fn.modal;
  t.fn.modal = D, t.fn.modal.Constructor = o, t.fn.modal.noConflict = function() {
    return t.fn.modal = k, this;
  }, t(document).on("click.bs.modal.data-api", '[data-toggle="modal"]', function(c) {
    var n = t(this), a = n.attr("href"), r = n.attr("data-target") || a && a.replace(/.*(?=#[^\s]+$)/, ""), g = t(document).find(r), m = g.data("bs.modal") ? "toggle" : t.extend({ remote: !/#/.test(a) && a }, g.data(), n.data());
    n.is("a") && c.preventDefault(), g.one("show.bs.modal", function(b) {
      b.isDefaultPrevented() || g.one("hidden.bs.modal", function() {
        n.is(":visible") && n.trigger("focus");
      });
    }), D.call(g, m, this);
  });
}(R);
+function(t) {
  var o = ["sanitize", "whiteList", "sanitizeFn"], D = [
    "background",
    "cite",
    "href",
    "itemtype",
    "longdesc",
    "poster",
    "src",
    "xlink:href"
  ], k = /^aria-[\w-]*$/i, c = {
    // Global attributes allowed on any supplied element below.
    "*": ["class", "dir", "id", "lang", "role", k],
    a: ["target", "href", "title", "rel"],
    area: [],
    b: [],
    br: [],
    col: [],
    code: [],
    div: [],
    em: [],
    hr: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    i: [],
    img: ["src", "alt", "title", "width", "height"],
    li: [],
    ol: [],
    p: [],
    pre: [],
    s: [],
    small: [],
    span: [],
    sub: [],
    sup: [],
    strong: [],
    u: [],
    ul: []
  }, n = /^(?:(?:https?|mailto|ftp|tel|file):|[^&:/?#]*(?:[/?#]|$))/gi, a = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;
  function r(h, u) {
    var d = h.nodeName.toLowerCase();
    if (t.inArray(d, u) !== -1)
      return t.inArray(d, D) !== -1 ? !!(h.nodeValue.match(n) || h.nodeValue.match(a)) : !0;
    for (var f = t(u).filter(function(s, l) {
      return l instanceof RegExp;
    }), i = 0, e = f.length; i < e; i++)
      if (d.match(f[i]))
        return !0;
    return !1;
  }
  function g(h, u, d) {
    if (h.length === 0)
      return h;
    if (d && typeof d == "function")
      return d(h);
    if (!document.implementation || !document.implementation.createHTMLDocument)
      return h;
    var f = document.implementation.createHTMLDocument("sanitization");
    f.body.innerHTML = h;
    for (var i = t.map(u, function(_, S) {
      return S;
    }), e = t(f.body).find("*"), s = 0, l = e.length; s < l; s++) {
      var p = e[s], v = p.nodeName.toLowerCase();
      if (t.inArray(v, i) === -1) {
        p.parentNode.removeChild(p);
        continue;
      }
      for (var y = t.map(p.attributes, function(_) {
        return _;
      }), T = [].concat(u["*"] || [], u[v] || []), C = 0, x = y.length; C < x; C++)
        r(y[C], T) || p.removeAttribute(y[C].nodeName);
    }
    return f.body.innerHTML;
  }
  var m = function(h, u) {
    this.type = null, this.options = null, this.enabled = null, this.timeout = null, this.hoverState = null, this.$element = null, this.inState = null, this.init("tooltip", h, u);
  };
  m.VERSION = "3.4.1", m.TRANSITION_DURATION = 150, m.DEFAULTS = {
    animation: !0,
    placement: "top",
    selector: !1,
    template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    trigger: "hover focus",
    title: "",
    delay: 0,
    html: !1,
    container: !1,
    viewport: {
      selector: "body",
      padding: 0
    },
    sanitize: !0,
    sanitizeFn: null,
    whiteList: c
  }, m.prototype.init = function(h, u, d) {
    if (this.enabled = !0, this.type = h, this.$element = t(u), this.options = this.getOptions(d), this.$viewport = this.options.viewport && t(document).find(t.isFunction(this.options.viewport) ? this.options.viewport.call(this, this.$element) : this.options.viewport.selector || this.options.viewport), this.inState = { click: !1, hover: !1, focus: !1 }, this.$element[0] instanceof document.constructor && !this.options.selector)
      throw new Error("`selector` option must be specified when initializing " + this.type + " on the window.document object!");
    for (var f = this.options.trigger.split(" "), i = f.length; i--; ) {
      var e = f[i];
      if (e == "click")
        this.$element.on("click." + this.type, this.options.selector, t.proxy(this.toggle, this));
      else if (e != "manual") {
        var s = e == "hover" ? "mouseenter" : "focusin", l = e == "hover" ? "mouseleave" : "focusout";
        this.$element.on(s + "." + this.type, this.options.selector, t.proxy(this.enter, this)), this.$element.on(l + "." + this.type, this.options.selector, t.proxy(this.leave, this));
      }
    }
    this.options.selector ? this._options = t.extend({}, this.options, { trigger: "manual", selector: "" }) : this.fixTitle();
  }, m.prototype.getDefaults = function() {
    return m.DEFAULTS;
  }, m.prototype.getOptions = function(h) {
    var u = this.$element.data();
    for (var d in u)
      u.hasOwnProperty(d) && t.inArray(d, o) !== -1 && delete u[d];
    return h = t.extend({}, this.getDefaults(), u, h), h.delay && typeof h.delay == "number" && (h.delay = {
      show: h.delay,
      hide: h.delay
    }), h.sanitize && (h.template = g(h.template, h.whiteList, h.sanitizeFn)), h;
  }, m.prototype.getDelegateOptions = function() {
    var h = {}, u = this.getDefaults();
    return this._options && t.each(this._options, function(d, f) {
      u[d] != f && (h[d] = f);
    }), h;
  }, m.prototype.enter = function(h) {
    var u = h instanceof this.constructor ? h : t(h.currentTarget).data("bs." + this.type);
    if (u || (u = new this.constructor(h.currentTarget, this.getDelegateOptions()), t(h.currentTarget).data("bs." + this.type, u)), h instanceof t.Event && (u.inState[h.type == "focusin" ? "focus" : "hover"] = !0), u.tip().hasClass("in") || u.hoverState == "in") {
      u.hoverState = "in";
      return;
    }
    if (clearTimeout(u.timeout), u.hoverState = "in", !u.options.delay || !u.options.delay.show) return u.show();
    u.timeout = setTimeout(function() {
      u.hoverState == "in" && u.show();
    }, u.options.delay.show);
  }, m.prototype.isInStateTrue = function() {
    for (var h in this.inState)
      if (this.inState[h]) return !0;
    return !1;
  }, m.prototype.leave = function(h) {
    var u = h instanceof this.constructor ? h : t(h.currentTarget).data("bs." + this.type);
    if (u || (u = new this.constructor(h.currentTarget, this.getDelegateOptions()), t(h.currentTarget).data("bs." + this.type, u)), h instanceof t.Event && (u.inState[h.type == "focusout" ? "focus" : "hover"] = !1), !u.isInStateTrue()) {
      if (clearTimeout(u.timeout), u.hoverState = "out", !u.options.delay || !u.options.delay.hide) return u.hide();
      u.timeout = setTimeout(function() {
        u.hoverState == "out" && u.hide();
      }, u.options.delay.hide);
    }
  }, m.prototype.show = function() {
    var h = t.Event("show.bs." + this.type);
    if (this.hasContent() && this.enabled) {
      this.$element.trigger(h);
      var u = t.contains(this.$element[0].ownerDocument.documentElement, this.$element[0]);
      if (h.isDefaultPrevented() || !u) return;
      var d = this, f = this.tip(), i = this.getUID(this.type);
      this.setContent(), f.attr("id", i), this.$element.attr("aria-describedby", i), this.options.animation && f.addClass("fade");
      var e = typeof this.options.placement == "function" ? this.options.placement.call(this, f[0], this.$element[0]) : this.options.placement, s = /\s?auto?\s?/i, l = s.test(e);
      l && (e = e.replace(s, "") || "top"), f.detach().css({ top: 0, left: 0, display: "block" }).addClass(e).data("bs." + this.type, this), this.options.container ? f.appendTo(t(document).find(this.options.container)) : f.insertAfter(this.$element), this.$element.trigger("inserted.bs." + this.type);
      var p = this.getPosition(), v = f[0].offsetWidth, y = f[0].offsetHeight;
      if (l) {
        var T = e, C = this.getPosition(this.$viewport);
        e = e == "bottom" && p.bottom + y > C.bottom ? "top" : e == "top" && p.top - y < C.top ? "bottom" : e == "right" && p.right + v > C.width ? "left" : e == "left" && p.left - v < C.left ? "right" : e, f.removeClass(T).addClass(e);
      }
      var x = this.getCalculatedOffset(e, p, v, y);
      this.applyPlacement(x, e);
      var _ = function() {
        var S = d.hoverState;
        d.$element.trigger("shown.bs." + d.type), d.hoverState = null, S == "out" && d.leave(d);
      };
      t.support.transition && this.$tip.hasClass("fade") ? f.one("bsTransitionEnd", _).emulateTransitionEnd(m.TRANSITION_DURATION) : _();
    }
  }, m.prototype.applyPlacement = function(h, u) {
    var d = this.tip(), f = d[0].offsetWidth, i = d[0].offsetHeight, e = parseInt(d.css("margin-top"), 10), s = parseInt(d.css("margin-left"), 10);
    isNaN(e) && (e = 0), isNaN(s) && (s = 0), h.top += e, h.left += s, t.offset.setOffset(d[0], t.extend({
      using: function(x) {
        d.css({
          top: Math.round(x.top),
          left: Math.round(x.left)
        });
      }
    }, h), 0), d.addClass("in");
    var l = d[0].offsetWidth, p = d[0].offsetHeight;
    u == "top" && p != i && (h.top = h.top + i - p);
    var v = this.getViewportAdjustedDelta(u, h, l, p);
    v.left ? h.left += v.left : h.top += v.top;
    var y = /top|bottom/.test(u), T = y ? v.left * 2 - f + l : v.top * 2 - i + p, C = y ? "offsetWidth" : "offsetHeight";
    d.offset(h), this.replaceArrow(T, d[0][C], y);
  }, m.prototype.replaceArrow = function(h, u, d) {
    this.arrow().css(d ? "left" : "top", 50 * (1 - h / u) + "%").css(d ? "top" : "left", "");
  }, m.prototype.setContent = function() {
    var h = this.tip(), u = this.getTitle();
    this.options.html ? (this.options.sanitize && (u = g(u, this.options.whiteList, this.options.sanitizeFn)), h.find(".tooltip-inner").html(u)) : h.find(".tooltip-inner").text(u), h.removeClass("fade in top bottom left right");
  }, m.prototype.hide = function(h) {
    var u = this, d = t(this.$tip), f = t.Event("hide.bs." + this.type);
    function i() {
      u.hoverState != "in" && d.detach(), u.$element && u.$element.removeAttr("aria-describedby").trigger("hidden.bs." + u.type), h && h();
    }
    if (this.$element.trigger(f), !f.isDefaultPrevented())
      return d.removeClass("in"), t.support.transition && d.hasClass("fade") ? d.one("bsTransitionEnd", i).emulateTransitionEnd(m.TRANSITION_DURATION) : i(), this.hoverState = null, this;
  }, m.prototype.fixTitle = function() {
    var h = this.$element;
    (h.attr("title") || typeof h.attr("data-original-title") != "string") && h.attr("data-original-title", h.attr("title") || "").attr("title", "");
  }, m.prototype.hasContent = function() {
    return this.getTitle();
  }, m.prototype.getPosition = function(h) {
    h = h || this.$element;
    var u = h[0], d = u.tagName == "BODY", f = u.getBoundingClientRect();
    f.width == null && (f = t.extend({}, f, { width: f.right - f.left, height: f.bottom - f.top }));
    var i = window.SVGElement && u instanceof window.SVGElement, e = d ? { top: 0, left: 0 } : i ? null : h.offset(), s = { scroll: d ? document.documentElement.scrollTop || document.body.scrollTop : h.scrollTop() }, l = d ? { width: t(window).width(), height: t(window).height() } : null;
    return t.extend({}, f, s, l, e);
  }, m.prototype.getCalculatedOffset = function(h, u, d, f) {
    return h == "bottom" ? { top: u.top + u.height, left: u.left + u.width / 2 - d / 2 } : h == "top" ? { top: u.top - f, left: u.left + u.width / 2 - d / 2 } : h == "left" ? { top: u.top + u.height / 2 - f / 2, left: u.left - d } : (
      /* placement == 'right' */
      { top: u.top + u.height / 2 - f / 2, left: u.left + u.width }
    );
  }, m.prototype.getViewportAdjustedDelta = function(h, u, d, f) {
    var i = { top: 0, left: 0 };
    if (!this.$viewport) return i;
    var e = this.options.viewport && this.options.viewport.padding || 0, s = this.getPosition(this.$viewport);
    if (/right|left/.test(h)) {
      var l = u.top - e - s.scroll, p = u.top + e - s.scroll + f;
      l < s.top ? i.top = s.top - l : p > s.top + s.height && (i.top = s.top + s.height - p);
    } else {
      var v = u.left - e, y = u.left + e + d;
      v < s.left ? i.left = s.left - v : y > s.right && (i.left = s.left + s.width - y);
    }
    return i;
  }, m.prototype.getTitle = function() {
    var h, u = this.$element, d = this.options;
    return h = u.attr("data-original-title") || (typeof d.title == "function" ? d.title.call(u[0]) : d.title), h;
  }, m.prototype.getUID = function(h) {
    do
      h += ~~(Math.random() * 1e6);
    while (document.getElementById(h));
    return h;
  }, m.prototype.tip = function() {
    if (!this.$tip && (this.$tip = t(this.options.template), this.$tip.length != 1))
      throw new Error(this.type + " `template` option must consist of exactly 1 top-level element!");
    return this.$tip;
  }, m.prototype.arrow = function() {
    return this.$arrow = this.$arrow || this.tip().find(".tooltip-arrow");
  }, m.prototype.enable = function() {
    this.enabled = !0;
  }, m.prototype.disable = function() {
    this.enabled = !1;
  }, m.prototype.toggleEnabled = function() {
    this.enabled = !this.enabled;
  }, m.prototype.toggle = function(h) {
    var u = this;
    h && (u = t(h.currentTarget).data("bs." + this.type), u || (u = new this.constructor(h.currentTarget, this.getDelegateOptions()), t(h.currentTarget).data("bs." + this.type, u))), h ? (u.inState.click = !u.inState.click, u.isInStateTrue() ? u.enter(u) : u.leave(u)) : u.tip().hasClass("in") ? u.leave(u) : u.enter(u);
  }, m.prototype.destroy = function() {
    var h = this;
    clearTimeout(this.timeout), this.hide(function() {
      h.$element.off("." + h.type).removeData("bs." + h.type), h.$tip && h.$tip.detach(), h.$tip = null, h.$arrow = null, h.$viewport = null, h.$element = null;
    });
  }, m.prototype.sanitizeHtml = function(h) {
    return g(h, this.options.whiteList, this.options.sanitizeFn);
  };
  function b(h) {
    return this.each(function() {
      var u = t(this), d = u.data("bs.tooltip"), f = typeof h == "object" && h;
      !d && /destroy|hide/.test(h) || (d || u.data("bs.tooltip", d = new m(this, f)), typeof h == "string" && d[h]());
    });
  }
  var w = t.fn.tooltip;
  t.fn.tooltip = b, t.fn.tooltip.Constructor = m, t.fn.tooltip.noConflict = function() {
    return t.fn.tooltip = w, this;
  };
}(R);
+function(t) {
  var o = function(c, n) {
    this.init("popover", c, n);
  };
  if (!t.fn.tooltip) throw new Error("Popover requires tooltip.js");
  o.VERSION = "3.4.1", o.DEFAULTS = t.extend({}, t.fn.tooltip.Constructor.DEFAULTS, {
    placement: "right",
    trigger: "click",
    content: "",
    template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
  }), o.prototype = t.extend({}, t.fn.tooltip.Constructor.prototype), o.prototype.constructor = o, o.prototype.getDefaults = function() {
    return o.DEFAULTS;
  }, o.prototype.setContent = function() {
    var c = this.tip(), n = this.getTitle(), a = this.getContent();
    if (this.options.html) {
      var r = typeof a;
      this.options.sanitize && (n = this.sanitizeHtml(n), r === "string" && (a = this.sanitizeHtml(a))), c.find(".popover-title").html(n), c.find(".popover-content").children().detach().end()[r === "string" ? "html" : "append"](a);
    } else
      c.find(".popover-title").text(n), c.find(".popover-content").children().detach().end().text(a);
    c.removeClass("fade top bottom left right in"), c.find(".popover-title").html() || c.find(".popover-title").hide();
  }, o.prototype.hasContent = function() {
    return this.getTitle() || this.getContent();
  }, o.prototype.getContent = function() {
    var c = this.$element, n = this.options;
    return c.attr("data-content") || (typeof n.content == "function" ? n.content.call(c[0]) : n.content);
  }, o.prototype.arrow = function() {
    return this.$arrow = this.$arrow || this.tip().find(".arrow");
  };
  function D(c) {
    return this.each(function() {
      var n = t(this), a = n.data("bs.popover"), r = typeof c == "object" && c;
      !a && /destroy|hide/.test(c) || (a || n.data("bs.popover", a = new o(this, r)), typeof c == "string" && a[c]());
    });
  }
  var k = t.fn.popover;
  t.fn.popover = D, t.fn.popover.Constructor = o, t.fn.popover.noConflict = function() {
    return t.fn.popover = k, this;
  };
}(R);
+function(t) {
  function o(c, n) {
    this.$body = t(document.body), this.$scrollElement = t(c).is(document.body) ? t(window) : t(c), this.options = t.extend({}, o.DEFAULTS, n), this.selector = (this.options.target || "") + " .nav li > a", this.offsets = [], this.targets = [], this.activeTarget = null, this.scrollHeight = 0, this.$scrollElement.on("scroll.bs.scrollspy", t.proxy(this.process, this)), this.refresh(), this.process();
  }
  o.VERSION = "3.4.1", o.DEFAULTS = {
    offset: 10
  }, o.prototype.getScrollHeight = function() {
    return this.$scrollElement[0].scrollHeight || Math.max(this.$body[0].scrollHeight, document.documentElement.scrollHeight);
  }, o.prototype.refresh = function() {
    var c = this, n = "offset", a = 0;
    this.offsets = [], this.targets = [], this.scrollHeight = this.getScrollHeight(), t.isWindow(this.$scrollElement[0]) || (n = "position", a = this.$scrollElement.scrollTop()), this.$body.find(this.selector).map(function() {
      var r = t(this), g = r.data("target") || r.attr("href"), m = /^#./.test(g) && t(g);
      return m && m.length && m.is(":visible") && [[m[n]().top + a, g]] || null;
    }).sort(function(r, g) {
      return r[0] - g[0];
    }).each(function() {
      c.offsets.push(this[0]), c.targets.push(this[1]);
    });
  }, o.prototype.process = function() {
    var c = this.$scrollElement.scrollTop() + this.options.offset, n = this.getScrollHeight(), a = this.options.offset + n - this.$scrollElement.height(), r = this.offsets, g = this.targets, m = this.activeTarget, b;
    if (this.scrollHeight != n && this.refresh(), c >= a)
      return m != (b = g[g.length - 1]) && this.activate(b);
    if (m && c < r[0])
      return this.activeTarget = null, this.clear();
    for (b = r.length; b--; )
      m != g[b] && c >= r[b] && (r[b + 1] === void 0 || c < r[b + 1]) && this.activate(g[b]);
  }, o.prototype.activate = function(c) {
    this.activeTarget = c, this.clear();
    var n = this.selector + '[data-target="' + c + '"],' + this.selector + '[href="' + c + '"]', a = t(n).parents("li").addClass("active");
    a.parent(".dropdown-menu").length && (a = a.closest("li.dropdown").addClass("active")), a.trigger("activate.bs.scrollspy");
  }, o.prototype.clear = function() {
    t(this.selector).parentsUntil(this.options.target, ".active").removeClass("active");
  };
  function D(c) {
    return this.each(function() {
      var n = t(this), a = n.data("bs.scrollspy"), r = typeof c == "object" && c;
      a || n.data("bs.scrollspy", a = new o(this, r)), typeof c == "string" && a[c]();
    });
  }
  var k = t.fn.scrollspy;
  t.fn.scrollspy = D, t.fn.scrollspy.Constructor = o, t.fn.scrollspy.noConflict = function() {
    return t.fn.scrollspy = k, this;
  }, t(window).on("load.bs.scrollspy.data-api", function() {
    t('[data-spy="scroll"]').each(function() {
      var c = t(this);
      D.call(c, c.data());
    });
  });
}(R);
+function(t) {
  var o = function(n) {
    this.element = t(n);
  };
  o.VERSION = "3.4.1", o.TRANSITION_DURATION = 150, o.prototype.show = function() {
    var n = this.element, a = n.closest("ul:not(.dropdown-menu)"), r = n.data("target");
    if (r || (r = n.attr("href"), r = r && r.replace(/.*(?=#[^\s]*$)/, "")), !n.parent("li").hasClass("active")) {
      var g = a.find(".active:last a"), m = t.Event("hide.bs.tab", {
        relatedTarget: n[0]
      }), b = t.Event("show.bs.tab", {
        relatedTarget: g[0]
      });
      if (g.trigger(m), n.trigger(b), !(b.isDefaultPrevented() || m.isDefaultPrevented())) {
        var w = t(document).find(r);
        this.activate(n.closest("li"), a), this.activate(w, w.parent(), function() {
          g.trigger({
            type: "hidden.bs.tab",
            relatedTarget: n[0]
          }), n.trigger({
            type: "shown.bs.tab",
            relatedTarget: g[0]
          });
        });
      }
    }
  }, o.prototype.activate = function(n, a, r) {
    var g = a.find("> .active"), m = r && t.support.transition && (g.length && g.hasClass("fade") || !!a.find("> .fade").length);
    function b() {
      g.removeClass("active").find("> .dropdown-menu > .active").removeClass("active").end().find('[data-toggle="tab"]').attr("aria-expanded", !1), n.addClass("active").find('[data-toggle="tab"]').attr("aria-expanded", !0), m ? (n[0].offsetWidth, n.addClass("in")) : n.removeClass("fade"), n.parent(".dropdown-menu").length && n.closest("li.dropdown").addClass("active").end().find('[data-toggle="tab"]').attr("aria-expanded", !0), r && r();
    }
    g.length && m ? g.one("bsTransitionEnd", b).emulateTransitionEnd(o.TRANSITION_DURATION) : b(), g.removeClass("in");
  };
  function D(n) {
    return this.each(function() {
      var a = t(this), r = a.data("bs.tab");
      r || a.data("bs.tab", r = new o(this)), typeof n == "string" && r[n]();
    });
  }
  var k = t.fn.tab;
  t.fn.tab = D, t.fn.tab.Constructor = o, t.fn.tab.noConflict = function() {
    return t.fn.tab = k, this;
  };
  var c = function(n) {
    n.preventDefault(), D.call(t(this), "show");
  };
  t(document).on("click.bs.tab.data-api", '[data-toggle="tab"]', c).on("click.bs.tab.data-api", '[data-toggle="pill"]', c);
}(R);
+function(t) {
  var o = function(c, n) {
    this.options = t.extend({}, o.DEFAULTS, n);
    var a = this.options.target === o.DEFAULTS.target ? t(this.options.target) : t(document).find(this.options.target);
    this.$target = a.on("scroll.bs.affix.data-api", t.proxy(this.checkPosition, this)).on("click.bs.affix.data-api", t.proxy(this.checkPositionWithEventLoop, this)), this.$element = t(c), this.affixed = null, this.unpin = null, this.pinnedOffset = null, this.checkPosition();
  };
  o.VERSION = "3.4.1", o.RESET = "affix affix-top affix-bottom", o.DEFAULTS = {
    offset: 0,
    target: window
  }, o.prototype.getState = function(c, n, a, r) {
    var g = this.$target.scrollTop(), m = this.$element.offset(), b = this.$target.height();
    if (a != null && this.affixed == "top") return g < a ? "top" : !1;
    if (this.affixed == "bottom")
      return a != null ? g + this.unpin <= m.top ? !1 : "bottom" : g + b <= c - r ? !1 : "bottom";
    var w = this.affixed == null, h = w ? g : m.top, u = w ? b : n;
    return a != null && g <= a ? "top" : r != null && h + u >= c - r ? "bottom" : !1;
  }, o.prototype.getPinnedOffset = function() {
    if (this.pinnedOffset) return this.pinnedOffset;
    this.$element.removeClass(o.RESET).addClass("affix");
    var c = this.$target.scrollTop(), n = this.$element.offset();
    return this.pinnedOffset = n.top - c;
  }, o.prototype.checkPositionWithEventLoop = function() {
    setTimeout(t.proxy(this.checkPosition, this), 1);
  }, o.prototype.checkPosition = function() {
    if (this.$element.is(":visible")) {
      var c = this.$element.height(), n = this.options.offset, a = n.top, r = n.bottom, g = Math.max(t(document).height(), t(document.body).height());
      typeof n != "object" && (r = a = n), typeof a == "function" && (a = n.top(this.$element)), typeof r == "function" && (r = n.bottom(this.$element));
      var m = this.getState(g, c, a, r);
      if (this.affixed != m) {
        this.unpin != null && this.$element.css("top", "");
        var b = "affix" + (m ? "-" + m : ""), w = t.Event(b + ".bs.affix");
        if (this.$element.trigger(w), w.isDefaultPrevented()) return;
        this.affixed = m, this.unpin = m == "bottom" ? this.getPinnedOffset() : null, this.$element.removeClass(o.RESET).addClass(b).trigger(b.replace("affix", "affixed") + ".bs.affix");
      }
      m == "bottom" && this.$element.offset({
        top: g - c - r
      });
    }
  };
  function D(c) {
    return this.each(function() {
      var n = t(this), a = n.data("bs.affix"), r = typeof c == "object" && c;
      a || n.data("bs.affix", a = new o(this, r)), typeof c == "string" && a[c]();
    });
  }
  var k = t.fn.affix;
  t.fn.affix = D, t.fn.affix.Constructor = o, t.fn.affix.noConflict = function() {
    return t.fn.affix = k, this;
  }, t(window).on("load", function() {
    t('[data-spy="affix"]').each(function() {
      var c = t(this), n = c.data();
      n.offset = n.offset || {}, n.offsetBottom != null && (n.offset.bottom = n.offsetBottom), n.offsetTop != null && (n.offset.top = n.offsetTop), D.call(c, n);
    });
  });
}(R);
const O = R, $ = q;
let K;
function J(t) {
  return this.each(function() {
    const o = O(this);
    o.attr("data-toggle", "dropdown"), t === "toggle" && o.trigger("click");
  });
}
O.fn.dropdown = J;
function B(t) {
  const o = t.attr("data-target"), D = o && O(o);
  return D && D.length ? D : t.parent();
}
function H(t) {
  t && t.which === 3 || O('[data-toggle="dropdown"]').each(function() {
    const o = O(this), D = B(o), k = { relatedTarget: this };
    if (!D.hasClass("open")) return;
    const c = t ? t.type : null;
    if (c === "click" && /input|textarea/i.test(t.target.tagName) && O.contains(D[0], t.target) || (D.trigger(t = O.Event("hide.bs.dropdown", k)), t.isDefaultPrevented() || D.attr("forceOpen") === "true" || o.hasClass("opening"))) return;
    const n = O(document.activeElement).filter('.editable, input[type="text"], input[type="textarea"], input[type="email"]');
    K && (document.body.classList.contains("smartphone") && c === "click" && n.length ? n.focus() : K.focus()), o.attr("aria-expanded", !1), D.removeClass("open").trigger(O.Event("hidden.bs.dropdown", k));
  });
}
function G(t) {
  const o = O(this), D = B(o), k = D.hasClass("open");
  if (t && (D[0] !== t.target || this === t.target) && D.attr("forceOpen", !1), H(), !k) {
    const c = { relatedTarget: this };
    D.trigger(t = O.Event("show.bs.dropdown", c)), K = o, o.attr("aria-expanded", !0), D.toggleClass("open").trigger(O.Event("shown.bs.dropdown", c)), o.focus();
  }
  return !1;
}
function Z(t) {
  if (t.which === 27) return H();
  if (!/(13|32|38|40)/.test(t.which)) return;
  const o = O(t.target), D = B(o), k = o.siblings("ul");
  D.hasClass("open") || (/(13|32)/.test(t.which) || o.trigger("click"), import("@/io.ox/core/a11y").then(function({ default: n }) {
    $.defer(function() {
      const a = n.getTabbable(k);
      /(13|32|40)/.test(t.which) && a.first(":visible").focus(), t.which === 38 && a.last(":visible").focus();
    });
  }));
}
function X(t) {
  if (console.log("ox bootstrap keydownMenu"), t.which === 27) return H();
  if (!/(38|40)/.test(t.which)) return;
  t.preventDefault();
  const o = O(t.target), k = o.closest("ul").find('a:visible[role^="menuitem"]');
  let c = k.index(o);
  t.which === 38 && c--, t.which === 40 && c++, c < 0 && (c += k.length), c >= k.length && (c -= k.length), $.defer(function() {
    k.eq(c).get(0).focus();
  });
}
function tt(t) {
  const o = O(t.target);
  t.which === 32 && o.is("a") && o.trigger("click");
}
function et() {
  const t = this;
  $.defer(function() {
    O.contains(t, document.activeElement) || H();
  });
}
function it(t) {
  t.originalEvent && H(t);
}
O(document).on("click.bs.dropdown.data-api", H).on("click.bs.dropdown.data-api", '[data-toggle="dropdown"]', G).on("keydown.bs.dropdown.data-api", '[data-toggle="dropdown"]', Z).on("keydown.bs.dropdown.data-api", ".dropdown-menu", X).on("keydown.bs.dropdown.data-api", "[role=menu]", tt).on("focusout.dropdown.data-api", ".dropdown-menu", et);
O(window).on("resize", $.throttle(it, 200));
const A = R, j = q;
A.fn.modal.Constructor.prototype._hide = A.fn.modal.Constructor.prototype.hide;
A.fn.modal.Constructor.prototype._show = A.fn.modal.Constructor.prototype.show;
A.fn.modal.Constructor.prototype.hide = function() {
  const t = this.$element.parent().find('[data-target="#' + this.$element.attr("id") + '"]');
  A.fn.modal.Constructor.prototype._hide.apply(this, arguments), t.focus();
};
A.fn.modal.Constructor.prototype.show = function() {
  A(".modal-dialog", this).attr({ role: "document" }), A.fn.modal.Constructor.prototype._show.apply(this, arguments);
};
const Q = function(t, o, D) {
  let k = (t.attr(o) || "").split(/\s+/);
  const c = A.inArray(D, k);
  c !== -1 && k.splice(c, 1), k = A.trim(k.join(" ")), k ? t.attr(o, k) : t.removeAttr(o);
}, nt = A.fn.popover.Constructor.prototype.setContent, st = A.fn.popover.Constructor.prototype.hide;
A.fn.popover.Constructor.prototype.setContent = function() {
  nt.apply(this, arguments);
  const t = this.tip(), o = t.attr("id") || j.uniqueId("ui-tooltip");
  t.attr({ role: "tooltip", id: o }), this.$element.attr("aria-describedby", o);
};
A.fn.popover.Constructor.prototype.hide = function() {
  return st.apply(this, arguments), Q(this.$element, "aria-describedby", this.tip().attr("id")), this;
};
const at = A.fn.tooltip.Constructor.prototype.show, rt = A.fn.tooltip.Constructor.prototype.hide, ot = A.fn.tooltip.Constructor.prototype.hasContent;
A.fn.tooltip.Constructor.prototype.show = function() {
  at.apply(this, arguments);
  const t = this.tip(), o = t.attr("id") || j.uniqueId("ui-tooltip");
  t.attr({ role: "tooltip", id: o }), this.$element && this.$element.attr({ "aria-describedby": o });
};
A.fn.tooltip.Constructor.prototype.hide = function() {
  return rt.apply(this, arguments), this.$element && Q(this.$element, "aria-describedby", this.tip().attr("id")), this;
};
A.fn.tooltip.Constructor.prototype.hasContent = function() {
  return this.$element ? ot.apply(this, arguments) : !1;
};
/*!
 * typeahead.js 0.10.5
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
(function(t) {
  var o = function() {
    return {
      isMsie: function() {
        return /(msie|trident)/i.test(navigator.userAgent) ? navigator.userAgent.match(/(msie |rv:)(\d+(.\d+)?)/i)[2] : !1;
      },
      isBlankString: function(w) {
        return !w || /^\s*$/.test(w);
      },
      escapeRegExChars: function(w) {
        return w.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
      },
      isString: function(w) {
        return typeof w == "string";
      },
      isNumber: function(w) {
        return typeof w == "number";
      },
      isArray: t.isArray,
      isFunction: t.isFunction,
      isObject: t.isPlainObject,
      isUndefined: function(w) {
        return typeof w > "u";
      },
      toStr: function(h) {
        return o.isUndefined(h) || h === null ? "" : h + "";
      },
      bind: t.proxy,
      each: function(w, h) {
        t.each(w, u);
        function u(d, f) {
          return h(f, d);
        }
      },
      map: t.map,
      filter: t.grep,
      every: function(w, h) {
        var u = !0;
        return w ? (t.each(w, function(d, f) {
          if (!(u = h.call(null, f, d, w)))
            return !1;
        }), !!u) : u;
      },
      some: function(w, h) {
        var u = !1;
        return w ? (t.each(w, function(d, f) {
          if (u = h.call(null, f, d, w))
            return !1;
        }), !!u) : u;
      },
      mixin: t.extend,
      getUniqueId: /* @__PURE__ */ function() {
        var w = 0;
        return function() {
          return w++;
        };
      }(),
      templatify: function(h) {
        return t.isFunction(h) ? h : u;
        function u() {
          return String(h);
        }
      },
      defer: function(w) {
        setTimeout(w, 0);
      },
      debounce: function(w, h, u) {
        var d, f;
        return function() {
          var i = this, e = arguments, s, l;
          return s = function() {
            d = null, u || (f = w.apply(i, e));
          }, l = u && !d, clearTimeout(d), d = setTimeout(s, h), l && (f = w.apply(i, e)), f;
        };
      },
      throttle: function(w, h) {
        var u, d, f, i, e, s;
        return e = 0, s = function() {
          e = /* @__PURE__ */ new Date(), f = null, i = w.apply(u, d);
        }, function() {
          var l = /* @__PURE__ */ new Date(), p = h - (l - e);
          return u = this, d = arguments, p <= 0 ? (clearTimeout(f), f = null, e = l, i = w.apply(u, d)) : f || (f = setTimeout(s, p)), i;
        };
      },
      noop: function() {
      }
    };
  }(), D = /* @__PURE__ */ function() {
    return {
      wrapper: '<span class="twitter-typeahead"></span>',
      dropdown: '<span class="tt-dropdown-menu"></span>',
      dataset: '<div class="tt-dataset-%CLASS%"></div>',
      suggestions: '<span class="tt-suggestions"></span>',
      suggestion: '<div class="tt-suggestion"></div>'
    };
  }(), k = /* @__PURE__ */ function() {
    var w = {
      wrapper: {
        position: "relative",
        display: "inline-block"
      },
      hint: {
        position: "absolute",
        top: "0",
        left: "0",
        borderColor: "transparent",
        boxShadow: "none",
        opacity: "1"
      },
      input: {
        position: "relative",
        verticalAlign: "top",
        backgroundColor: "transparent"
      },
      inputWithNoHint: {
        position: "relative",
        verticalAlign: "top"
      },
      dropdown: {
        position: "absolute",
        top: "100%",
        left: "0",
        zIndex: "100",
        display: "none"
      },
      suggestions: {
        display: "block"
      },
      suggestion: {
        whiteSpace: "nowrap",
        cursor: "pointer"
      },
      suggestionChild: {
        whiteSpace: "normal"
      },
      ltr: {
        left: "0",
        right: "auto"
      },
      rtl: {
        left: "auto",
        right: " 0"
      }
    };
    return w;
  }(), c = function() {
    var w = "typeahead:";
    function h(u) {
      (!u || !u.el) && t.error("EventBus initialized without el"), this.$el = t(u.el);
    }
    return o.mixin(h.prototype, {
      trigger: function(u) {
        var d = [].slice.call(arguments, 1);
        this.$el.trigger(w + u, d);
      }
    }), h;
  }(), n = function() {
    var w = /\s+/, h = l();
    return {
      onSync: f,
      onAsync: d,
      off: i,
      trigger: e
    };
    function u(v, y, T, C) {
      var x;
      if (!T)
        return this;
      for (y = y.split(w), T = C ? p(T, C) : T, this._callbacks = this._callbacks || {}; x = y.shift(); )
        this._callbacks[x] = this._callbacks[x] || {
          sync: [],
          async: []
        }, this._callbacks[x][v].push(T);
      return this;
    }
    function d(v, y, T) {
      return u.call(this, "async", v, y, T);
    }
    function f(v, y, T) {
      return u.call(this, "sync", v, y, T);
    }
    function i(v) {
      var y;
      if (!this._callbacks)
        return this;
      for (v = v.split(w); y = v.shift(); )
        delete this._callbacks[y];
      return this;
    }
    function e(v) {
      var y, T, C, x, _;
      if (!this._callbacks)
        return this;
      for (v = v.split(w), C = [].slice.call(arguments, 1); (y = v.shift()) && (T = this._callbacks[y]); )
        x = s(T.sync, this, [y].concat(C)), _ = s(T.async, this, [y].concat(C)), x() && h(_);
      return this;
    }
    function s(v, y, T) {
      return C;
      function C() {
        for (var x, _ = 0, S = v.length; !x && _ < S; _ += 1)
          x = v[_].apply(y, T) === !1;
        return !x;
      }
    }
    function l() {
      var v;
      return window.setImmediate ? v = function(T) {
        setImmediate(function() {
          T();
        });
      } : v = function(T) {
        setTimeout(function() {
          T();
        }, 0);
      }, v;
    }
    function p(v, y) {
      return v.bind ? v.bind(y) : function() {
        v.apply(y, [].slice.call(arguments, 0));
      };
    }
  }(), a = /* @__PURE__ */ function(w) {
    var h = {
      node: null,
      pattern: null,
      tagName: "strong",
      className: null,
      wordsOnly: !1,
      caseSensitive: !1
    };
    return function(f) {
      var i;
      if (f = o.mixin({}, h, f), !f.node || !f.pattern)
        return;
      f.pattern = o.isArray(f.pattern) ? f.pattern : [f.pattern], i = u(f.pattern, f.caseSensitive, f.wordsOnly), s(f.node, e);
      function e(l) {
        var p, v, y;
        return (p = i.exec(l.data)) && (y = w.createElement(f.tagName), f.className && (y.className = f.className), v = l.splitText(p.index), v.splitText(p[0].length), y.appendChild(v.cloneNode(!0)), l.parentNode.replaceChild(y, v)), !!p;
      }
      function s(l, p) {
        for (var v, y = 3, T = 0; T < l.childNodes.length; T++)
          v = l.childNodes[T], v.nodeType === y ? T += p(v) ? 1 : 0 : v.nodeName.toLowerCase() !== "svg" && s(v, p);
      }
    };
    function u(d, f, i) {
      for (var e = [], s, l = 0, p = d.length; l < p; l++)
        e.push(o.escapeRegExChars(d[l]));
      return s = i ? "\\b(" + e.join("|") + ")\\b" : "(" + e.join("|") + ")", f ? new RegExp(s) : new RegExp(s, "i");
    }
  }(window.document), r = function() {
    var w;
    w = {
      9: "tab",
      27: "esc",
      37: "left",
      39: "right",
      13: "enter",
      38: "up",
      40: "down"
    };
    function h(i) {
      var e, s, l, p;
      i = i || {}, i.input || t.error("input is missing"), e = o.bind(this._onBlur, this), s = o.bind(this._onFocus, this), l = o.bind(this._onKeydown, this), p = o.bind(this._onInput, this), this.$hint = t(i.hint), this.$input = t(i.input).on("blur.tt", e).on("focus.tt", s).on("keydown.tt", l), this.$hint.length === 0 && (this.setHint = this.getHint = this.clearHint = this.clearHintIfInvalid = o.noop), this.$input.on("input.tt", p), this.query = this.$input.val(), this.$overflowHelper = u(this.$input);
    }
    return h.normalizeQuery = function(i) {
      return (i || "").replace(/^\s*/g, "").replace(/\s{2,}/g, " ");
    }, o.mixin(h.prototype, n, {
      _onBlur: function() {
        this.resetInputValue(), this.trigger("blurred");
      },
      _onFocus: function() {
        this.trigger("focused");
      },
      _onKeydown: function(e) {
        var s = w[e.which || e.keyCode];
        this._managePreventDefault(s, e), s && this._shouldTrigger(s, e) && this.trigger(s + "Keyed", e);
      },
      _onInput: function() {
        this._checkInputValue();
      },
      _managePreventDefault: function(e, s) {
        var l, p, v;
        switch (e) {
          case "tab":
            p = this.getHint(), v = this.getInputValue(), l = p && p !== v && !f(s);
            break;
          case "up":
          case "down":
            l = !f(s);
            break;
          default:
            l = !1;
        }
        l && s.preventDefault();
      },
      _shouldTrigger: function(e, s) {
        var l;
        switch (e) {
          case "tab":
            l = !f(s);
            break;
          default:
            l = !0;
        }
        return l;
      },
      _checkInputValue: function() {
        var e, s, l;
        e = this.getInputValue(), s = d(e, this.query), l = s ? this.query.length !== e.length : !1, this.query = e, s ? l && this.trigger("whitespaceChanged", this.query) : this.trigger("queryChanged", this.query);
      },
      focus: function() {
        this.$input.focus();
      },
      blur: function() {
        this.$input.blur();
      },
      getQuery: function() {
        return this.query;
      },
      setQuery: function(e) {
        this.query = e;
      },
      getInputValue: function() {
        return this.$input.val();
      },
      setInputValue: function(e, s) {
        this.$input.val(e), s ? this.clearHint() : this._checkInputValue();
      },
      resetInputValue: function() {
        this.setInputValue(this.query, !0);
      },
      getHint: function() {
        return this.$hint.val();
      },
      setHint: function(e) {
        this.$hint.val(e);
      },
      clearHint: function() {
        this.setHint("");
      },
      clearHintIfInvalid: function() {
        var e, s, l, p;
        e = this.getInputValue(), s = this.getHint(), l = e !== s && s.indexOf(e) === 0, p = e !== "" && l && !this.hasOverflow(), !p && this.clearHint();
      },
      getLanguageDirection: function() {
        return (this.$input.css("direction") || "ltr").toLowerCase();
      },
      hasOverflow: function() {
        var e = this.$input.width() - 2;
        return this.$overflowHelper.text(this.getInputValue()), this.$overflowHelper.width() >= e;
      },
      isCursorAtEnd: function() {
        var i, e, s;
        return i = this.$input.val().length, e = this.$input[0].selectionStart, o.isNumber(e) ? e === i : document.selection ? (s = document.selection.createRange(), s.moveStart("character", -i), i === s.text.length) : !0;
      },
      destroy: function() {
        this.$hint.off(".tt"), this.$input.off(".tt"), this.$hint = this.$input = this.$overflowHelper = null;
      }
    }), h;
    function u(i) {
      return t('<pre aria-hidden="true"></pre>').css({
        position: "absolute",
        visibility: "hidden",
        whiteSpace: "pre",
        fontFamily: i.css("font-family"),
        fontSize: i.css("font-size"),
        fontStyle: i.css("font-style"),
        fontVariant: i.css("font-variant"),
        fontWeight: i.css("font-weight"),
        wordSpacing: i.css("word-spacing"),
        letterSpacing: i.css("letter-spacing"),
        textIndent: i.css("text-indent"),
        textRendering: i.css("text-rendering"),
        textTransform: i.css("text-transform")
      }).insertAfter(i);
    }
    function d(i, e) {
      return h.normalizeQuery(i) === h.normalizeQuery(e);
    }
    function f(i) {
      return i.altKey || i.ctrlKey || i.metaKey || i.shiftKey;
    }
  }(), g = function() {
    var w = "ttDataset", h = "ttValue", u = "ttDatum";
    function d(s) {
      s = s || {}, s.templates = s.templates || {}, s.source || t.error("missing source"), s.name && !e(s.name) && t.error("invalid dataset name: " + s.name), this.query = null, this.highlight = !!s.highlight, this.name = s.name || o.getUniqueId(), this.source = s.source, this.displayFn = f(s.display || s.displayKey), this.templates = i(s.templates, this.displayFn), this.$el = t(D.dataset.replace("%CLASS%", this.name));
    }
    return d.extractDatasetName = function(l) {
      return t(l).data(w);
    }, d.extractValue = function(l) {
      return t(l).data(h);
    }, d.extractDatum = function(l) {
      return t(l).data(u);
    }, o.mixin(d.prototype, n, {
      _render: function(l, p) {
        if (!this.$el)
          return;
        var v = this, y;
        this.$el.empty(), y = p && p.length, !y && this.templates.empty ? this.$el.html(T()).prepend(v.templates.header ? x() : null).append(v.templates.footer ? _() : null) : y && this.$el.html(C()).prepend(v.templates.header ? x() : null).append(v.templates.footer ? _() : null), this.trigger("rendered");
        function T() {
          return v.templates.empty({
            query: l,
            isEmpty: !0
          });
        }
        function C() {
          var S, F;
          return S = t(D.suggestions).css(k.suggestions), F = o.map(p, E), S.append.apply(S, F), v.highlight && a({
            className: "tt-highlight",
            node: S[0],
            pattern: l
          }), S;
          function E(M) {
            var L;
            return L = t(D.suggestion).append(v.templates.suggestion(M)).data(w, v.name).data(h, v.displayFn(M)).data(u, M), L.children().each(function() {
              t(this).css(k.suggestionChild);
            }), L;
          }
        }
        function x() {
          return v.templates.header({
            query: l,
            isEmpty: !y
          });
        }
        function _() {
          return v.templates.footer({
            query: l,
            isEmpty: !y
          });
        }
      },
      getRoot: function() {
        return this.$el;
      },
      update: function(l) {
        var p = this;
        this.query = l, this.canceled = !1, this.source(l, v);
        function v(y) {
          !p.canceled && l === p.query && p._render(l, y);
        }
      },
      cancel: function() {
        this.canceled = !0;
      },
      clear: function() {
        this.cancel(), this.$el.empty(), this.trigger("rendered");
      },
      isEmpty: function() {
        return this.$el.is(":empty");
      },
      destroy: function() {
        this.$el = null;
      }
    }), d;
    function f(s) {
      return s = s || "value", o.isFunction(s) ? s : l;
      function l(p) {
        return p[s];
      }
    }
    function i(s, l) {
      return {
        empty: s.empty && o.templatify(s.empty),
        header: s.header && o.templatify(s.header),
        footer: s.footer && o.templatify(s.footer),
        suggestion: s.suggestion || p
      };
      function p(v) {
        return "<p>" + l(v) + "</p>";
      }
    }
    function e(s) {
      return /^[_a-zA-Z0-9-]+$/.test(s);
    }
  }(), m = function() {
    function w(u) {
      var d = this, f, i, e;
      u = u || {}, u.menu || t.error("menu is required"), this.isOpen = !1, this.isEmpty = !0, this.datasets = o.map(u.datasets, h), f = o.bind(this._onSuggestionClick, this), i = o.bind(this._onSuggestionMouseEnter, this), e = o.bind(this._onSuggestionMouseLeave, this), this.$menu = t(u.menu).on("click.tt", ".tt-suggestion", f).on("mouseenter.tt", ".tt-suggestion", i).on("mouseleave.tt", ".tt-suggestion", e), o.each(this.datasets, function(s) {
        d.$menu.append(s.getRoot()), s.onSync("rendered", d._onRendered, d);
      });
    }
    return o.mixin(w.prototype, n, {
      _onSuggestionClick: function(d) {
        this.trigger("suggestionClicked", t(d.currentTarget));
      },
      _onSuggestionMouseEnter: function(d) {
        this._removeCursor(), this._setCursor(t(d.currentTarget), !0);
      },
      _onSuggestionMouseLeave: function() {
        this._removeCursor();
      },
      _onRendered: function() {
        this.isEmpty = o.every(this.datasets, d), this.isEmpty ? this._hide() : this.isOpen && this._show(), this.trigger("datasetRendered");
        function d(f) {
          return f.isEmpty();
        }
      },
      _hide: function() {
        this.$menu.hide();
      },
      _show: function() {
        this.$menu.css("display", "block");
      },
      _getSuggestions: function() {
        return this.$menu.find(".tt-suggestion");
      },
      _getCursor: function() {
        return this.$menu.find(".tt-cursor").first();
      },
      _setCursor: function(d, f) {
        d.first().addClass("tt-cursor"), !f && this.trigger("cursorMoved");
      },
      _removeCursor: function() {
        this._getCursor().removeClass("tt-cursor");
      },
      _moveCursor: function(d) {
        var f, i, e, s;
        if (this.isOpen) {
          if (i = this._getCursor(), f = this._getSuggestions(), this._removeCursor(), e = f.index(i) + d, e = (e + 1) % (f.length + 1) - 1, e === -1) {
            this.trigger("cursorRemoved");
            return;
          } else e < -1 && (e = f.length - 1);
          this._setCursor(s = f.eq(e)), this._ensureVisible(s);
        }
      },
      _ensureVisible: function(d) {
        var f, i, e, s;
        f = d.position().top, i = f + d.outerHeight(!0), e = this.$menu.scrollTop(), s = this.$menu.height() + parseInt(this.$menu.css("paddingTop"), 10) + parseInt(this.$menu.css("paddingBottom"), 10), f < 0 ? this.$menu.scrollTop(e + f) : s < i && this.$menu.scrollTop(e + (i - s));
      },
      close: function() {
        this.isOpen && (this.isOpen = !1, this._removeCursor(), this._hide(), this.trigger("closed"));
      },
      open: function() {
        this.isOpen || (this.isOpen = !0, !this.isEmpty && this._show(), this.trigger("opened"));
      },
      setLanguageDirection: function(d) {
        this.$menu.css(d === "ltr" ? k.ltr : k.rtl);
      },
      moveCursorUp: function() {
        this._moveCursor(-1);
      },
      moveCursorDown: function() {
        this._moveCursor(1);
      },
      getDatumForSuggestion: function(d) {
        var f = null;
        return d.length && (f = {
          raw: g.extractDatum(d),
          value: g.extractValue(d),
          datasetName: g.extractDatasetName(d)
        }), f;
      },
      getDatumForCursor: function() {
        return this.getDatumForSuggestion(this._getCursor().first());
      },
      getDatumForTopSuggestion: function() {
        return this.getDatumForSuggestion(this._getSuggestions().first());
      },
      update: function(d) {
        o.each(this.datasets, f);
        function f(i) {
          i.update(d);
        }
      },
      empty: function() {
        o.each(this.datasets, d), this.isEmpty = !0;
        function d(f) {
          f.clear();
        }
      },
      isVisible: function() {
        return this.isOpen && !this.isEmpty;
      },
      destroy: function() {
        this.$menu.off(".tt"), this.$menu = null, o.each(this.datasets, d);
        function d(f) {
          f.destroy();
        }
      }
    }), w;
    function h(u) {
      return new g(u);
    }
  }(), b = function() {
    var w = "ttAttrs";
    function h(i) {
      var e, s, l;
      i = i || {}, i.input || t.error("missing input"), this.isActivated = !1, this.autoselect = !!i.autoselect, this.minLength = o.isNumber(i.minLength) ? i.minLength : 1, this.$node = u(i.input, i.withHint), e = this.$node.find(".tt-dropdown-menu"), s = this.$node.find(".tt-input"), l = this.$node.find(".tt-hint"), s.on("blur.tt", function(p) {
        var v;
        v = document.activeElement, e.is(v), e.has(v).length > 0;
      }), e.on("mousedown.tt", function(p) {
        p.preventDefault();
      }), this.eventBus = i.eventBus || new c({
        el: s
      }), this.dropdown = new m({
        menu: e,
        datasets: i.datasets
      }).onSync("suggestionClicked", this._onSuggestionClicked, this).onSync("cursorMoved", this._onCursorMoved, this).onSync("cursorRemoved", this._onCursorRemoved, this).onSync("opened", this._onOpened, this).onSync("closed", this._onClosed, this).onAsync("datasetRendered", this._onDatasetRendered, this), this.input = new r({
        input: s,
        hint: l
      }).onSync("focused", this._onFocused, this).onSync("blurred", this._onBlurred, this).onSync("enterKeyed", this._onEnterKeyed, this).onSync("tabKeyed", this._onTabKeyed, this).onSync("escKeyed", this._onEscKeyed, this).onSync("upKeyed", this._onUpKeyed, this).onSync("downKeyed", this._onDownKeyed, this).onSync("leftKeyed", this._onLeftKeyed, this).onSync("rightKeyed", this._onRightKeyed, this).onSync("queryChanged", this._onQueryChanged, this).onSync("whitespaceChanged", this._onWhitespaceChanged, this), this._setLanguageDirection();
    }
    return o.mixin(h.prototype, {
      _onSuggestionClicked: function(e, s) {
        var l;
        (l = this.dropdown.getDatumForSuggestion(s)) && this._select(l);
      },
      _onCursorMoved: function() {
        var e = this.dropdown.getDatumForCursor();
        this.input.setInputValue(e.value, !0), this.eventBus.trigger("cursorchanged", e.raw, e.datasetName);
      },
      _onCursorRemoved: function() {
        this.input.resetInputValue(), this._updateHint();
      },
      _onDatasetRendered: function() {
        this._updateHint();
      },
      _onOpened: function() {
        this._updateHint(), this.eventBus.trigger("opened");
      },
      _onClosed: function() {
        this.input.clearHint(), this.eventBus.trigger("closed");
      },
      _onFocused: function() {
        this.isActivated = !0, this.dropdown.open();
      },
      _onBlurred: function() {
        this.isActivated = !1, this.dropdown.empty(), this.dropdown.close();
      },
      _onEnterKeyed: function(e, s) {
        var l, p;
        l = this.dropdown.getDatumForCursor(), p = this.dropdown.getDatumForTopSuggestion(), l ? (this._select(l), s.preventDefault()) : this.autoselect && p && (this._select(p), s.preventDefault());
      },
      _onTabKeyed: function(e, s) {
        var l;
        (l = this.dropdown.getDatumForCursor()) ? (this._select(l), s.preventDefault()) : this._autocomplete(!0);
      },
      _onEscKeyed: function() {
        this.dropdown.close(), this.input.resetInputValue();
      },
      _onUpKeyed: function() {
        var e = this.input.getQuery();
        this.dropdown.isEmpty && e.length >= this.minLength ? this.dropdown.update(e) : this.dropdown.moveCursorUp(), this.dropdown.open();
      },
      _onDownKeyed: function() {
        var e = this.input.getQuery();
        this.dropdown.isEmpty && e.length >= this.minLength ? this.dropdown.update(e) : this.dropdown.moveCursorDown(), this.dropdown.open();
      },
      _onLeftKeyed: function() {
        this.dir === "rtl" && this._autocomplete();
      },
      _onRightKeyed: function() {
        this.dir === "ltr" && this._autocomplete();
      },
      _onQueryChanged: function(e, s) {
        this.input.clearHintIfInvalid(), s.length >= this.minLength ? this.dropdown.update(s) : this.dropdown.empty(), this.dropdown.open(), this._setLanguageDirection();
      },
      _onWhitespaceChanged: function() {
        this._updateHint(), this.dropdown.open();
      },
      _setLanguageDirection: function() {
        var e;
        this.dir !== (e = this.input.getLanguageDirection()) && (this.dir = e, this.$node.css("direction", e), this.dropdown.setLanguageDirection(e));
      },
      _updateHint: function() {
        var e, s, l, p, v, y;
        e = this.dropdown.getDatumForTopSuggestion(), e && this.dropdown.isVisible() && !this.input.hasOverflow() ? (s = this.input.getInputValue(), l = r.normalizeQuery(s), p = o.escapeRegExChars(l), v = new RegExp("^(?:" + p + ")(.+$)", "i"), y = v.exec(e.value), y ? this.input.setHint(s + y[1]) : this.input.clearHint()) : this.input.clearHint();
      },
      _autocomplete: function(e) {
        var s, l, p, v;
        s = this.input.getHint(), l = this.input.getQuery(), p = e || this.input.isCursorAtEnd(), s && l !== s && p && (v = this.dropdown.getDatumForTopSuggestion(), v && this.input.setInputValue(v.value), this.eventBus.trigger("autocompleted", v.raw, v.datasetName));
      },
      _select: function(e) {
        this.input.setQuery(e.value), this.input.setInputValue(e.value, !0), this._setLanguageDirection(), this.eventBus.trigger("selected", e.raw, e.datasetName), this.dropdown.close(), o.defer(o.bind(this.dropdown.empty, this.dropdown));
      },
      open: function() {
        this.dropdown.open();
      },
      close: function() {
        this.dropdown.close();
      },
      setVal: function(e) {
        e = o.toStr(e), this.isActivated ? this.input.setInputValue(e) : (this.input.setQuery(e), this.input.setInputValue(e, !0)), this._setLanguageDirection();
      },
      getVal: function() {
        return this.input.getQuery();
      },
      destroy: function() {
        this.input.destroy(), this.dropdown.destroy(), f(this.$node), this.$node = null;
      }
    }), h;
    function u(i, e) {
      var s, l, p, v;
      s = t(i), l = t(D.wrapper).css(k.wrapper), p = t(D.dropdown).css(k.dropdown), v = s.clone().css(k.hint).css(d(s)), v.val("").removeData().addClass("tt-hint").removeAttr("id name placeholder required").prop("readonly", !0).attr({
        autocomplete: "off",
        spellcheck: "false",
        tabindex: -1
      }), s.data(w, {
        dir: s.attr("dir"),
        autocomplete: s.attr("autocomplete"),
        spellcheck: s.attr("spellcheck"),
        style: s.attr("style")
      }), s.addClass("tt-input").attr({
        autocomplete: "off",
        spellcheck: !1
      }).css(e ? k.input : k.inputWithNoHint);
      try {
        !s.attr("dir") && s.attr("dir", "auto");
      } catch {
      }
      return s.wrap(l).parent().prepend(e ? v : null).append(p);
    }
    function d(i) {
      return {
        backgroundAttachment: i.css("background-attachment"),
        backgroundClip: i.css("background-clip"),
        backgroundColor: i.css("background-color"),
        backgroundImage: i.css("background-image"),
        backgroundOrigin: i.css("background-origin"),
        backgroundPosition: i.css("background-position"),
        backgroundRepeat: i.css("background-repeat"),
        backgroundSize: i.css("background-size")
      };
    }
    function f(i) {
      var e = i.find(".tt-input");
      o.each(e.data(w), function(s, l) {
        o.isUndefined(s) ? e.removeAttr(l) : e.attr(l, s);
      }), e.detach().removeData(w).removeClass("tt-input").insertAfter(i), i.remove();
    }
  }();
  (function() {
    var w, h, u;
    w = t.fn.typeahead, h = "ttTypeahead", u = {
      initialize: function(f, i) {
        return i = o.isArray(i) ? i : [].slice.call(arguments, 1), f = f || {}, this.each(e);
        function e() {
          var s = t(this), l;
          o.each(i, function(p) {
            p.highlight = !!f.highlight;
          }), l = new b({
            input: s,
            eventBus: new c({
              el: s
            }),
            withHint: o.isUndefined(f.hint) ? !0 : !!f.hint,
            minLength: f.minLength,
            autoselect: f.autoselect,
            datasets: i
          }), s.data(h, l);
        }
      },
      open: function() {
        return this.each(f);
        function f() {
          var i = t(this), e;
          (e = i.data(h)) && e.open();
        }
      },
      close: function() {
        return this.each(f);
        function f() {
          var i = t(this), e;
          (e = i.data(h)) && e.close();
        }
      },
      val: function(f) {
        return arguments.length ? this.each(i) : e(this.first());
        function i() {
          var s = t(this), l;
          (l = s.data(h)) && l.setVal(f);
        }
        function e(s) {
          var l, p;
          return (l = s.data(h)) && (p = l.getVal()), p;
        }
      },
      destroy: function() {
        return this.each(f);
        function f() {
          var i = t(this), e;
          (e = i.data(h)) && (e.destroy(), i.removeData(h));
        }
      }
    }, t.fn.typeahead = function(d) {
      var f;
      return u[d] && d !== "initialize" ? (f = this.filter(function() {
        return !!t(this).data(h);
      }), u[d].apply(f, [].slice.call(arguments, 1))) : u.initialize.apply(this, arguments);
    }, t.fn.typeahead.noConflict = function() {
      return t.fn.typeahead = w, this;
    };
  })();
})(R);
(function(t, o) {
  var D = t(window);
  function k() {
    return new Date(Date.UTC.apply(Date, arguments));
  }
  function c() {
    var i = /* @__PURE__ */ new Date();
    return k(i.getFullYear(), i.getMonth(), i.getDate());
  }
  function n(i) {
    return function() {
      return this[i].apply(this, arguments);
    };
  }
  var a = /* @__PURE__ */ function() {
    var i = {
      get: function(e) {
        return this.slice(e)[0];
      },
      contains: function(e) {
        for (var s = e && e.valueOf(), l = 0, p = this.length; l < p; l++)
          if (this[l].valueOf() === s)
            return l;
        return -1;
      },
      remove: function(e) {
        this.splice(e, 1);
      },
      replace: function(e) {
        e && (t.isArray(e) || (e = [e]), this.clear(), this.push.apply(this, e));
      },
      clear: function() {
        this.length = 0;
      },
      copy: function() {
        var e = new a();
        return e.replace(this), e;
      }
    };
    return function() {
      var e = [];
      return e.push.apply(e, arguments), t.extend(e, i), e;
    };
  }(), r = function(i, e) {
    this.dates = new a(), this.viewDate = c(), this.focusDate = null, this._process_options(e), this.element = t(i), this.isInline = !1, this.isInput = this.element.is("input"), this.component = this.element.is(".date") ? this.element.find(".add-on, .input-group-addon, .btn") : !1, this.hasInput = this.component && this.element.find("input").length, this.component && this.component.length === 0 && (this.component = !1), this.picker = t(f.template), this._buildEvents(), this._attachEvents(), this.isInline ? this.picker.addClass("datepicker-inline").appendTo(this.element) : this.picker.addClass("datepicker-dropdown dropdown-menu"), this.o.rtl && this.picker.addClass("datepicker-rtl"), this.viewMode = this.o.startView, this.o.calendarWeeks && this.picker.find("tfoot th.today, tfoot th.clear").attr("colspan", function(s, l) {
      return parseInt(l) + 1;
    }), this._allow_update = !1, this.setStartDate(this._o.startDate), this.setEndDate(this._o.endDate), this.setDaysOfWeekDisabled(this.o.daysOfWeekDisabled), this.fillDow(), this.fillMonths(), this._allow_update = !0, this.update(), this.showMode(), this.isInline && this.show();
  };
  r.prototype = {
    constructor: r,
    _process_options: function(i) {
      this._o = t.extend({}, this._o, i);
      var e = this.o = t.extend({}, this._o), s = e.language;
      switch (d[s] || (s = s.split("-")[0], d[s] || (s = h.language)), e.language = s, e.startView) {
        case 2:
        case "decade":
          e.startView = 2;
          break;
        case 1:
        case "year":
          e.startView = 1;
          break;
        default:
          e.startView = 0;
      }
      switch (e.minViewMode) {
        case 1:
        case "months":
          e.minViewMode = 1;
          break;
        case 2:
        case "years":
          e.minViewMode = 2;
          break;
        default:
          e.minViewMode = 0;
      }
      e.startView = Math.max(e.startView, e.minViewMode), e.multidate !== !0 && (e.multidate = Number(e.multidate) || !1, e.multidate !== !1 ? e.multidate = Math.max(0, e.multidate) : e.multidate = 1), e.multidateSeparator = String(e.multidateSeparator), e.weekStart %= 7, e.weekEnd = (e.weekStart + 6) % 7;
      var l = f.parseFormat(e.format);
      e.startDate !== -1 / 0 && (e.startDate ? e.startDate instanceof Date ? e.startDate = this._local_to_utc(this._zero_time(e.startDate)) : e.startDate = f.parseDate(e.startDate, l, e.language) : e.startDate = -1 / 0), e.endDate !== 1 / 0 && (e.endDate ? e.endDate instanceof Date ? e.endDate = this._local_to_utc(this._zero_time(e.endDate)) : e.endDate = f.parseDate(e.endDate, l, e.language) : e.endDate = 1 / 0), e.daysOfWeekDisabled = e.daysOfWeekDisabled || [], t.isArray(e.daysOfWeekDisabled) || (e.daysOfWeekDisabled = e.daysOfWeekDisabled.split(/[,\s]*/)), e.daysOfWeekDisabled = t.map(e.daysOfWeekDisabled, function(y) {
        return parseInt(y, 10);
      });
      var p = String(e.orientation).toLowerCase().split(/\s+/g), v = e.orientation.toLowerCase();
      if (p = t.grep(p, function(y) {
        return /^auto|left|right|top|bottom$/.test(y);
      }), e.orientation = { x: "auto", y: "auto" }, !(!v || v === "auto"))
        if (p.length === 1)
          switch (p[0]) {
            case "top":
            case "bottom":
              e.orientation.y = p[0];
              break;
            case "left":
            case "right":
              e.orientation.x = p[0];
              break;
          }
        else
          v = t.grep(p, function(y) {
            return /^left|right$/.test(y);
          }), e.orientation.x = v[0] || "auto", v = t.grep(p, function(y) {
            return /^top|bottom$/.test(y);
          }), e.orientation.y = v[0] || "auto";
    },
    _events: [],
    _secondaryEvents: [],
    _applyEvents: function(i) {
      for (var e = 0, s, l, p; e < i.length; e++)
        s = i[e][0], i[e].length === 2 ? (l = o, p = i[e][1]) : i[e].length === 3 && (l = i[e][1], p = i[e][2]), s.on(p, l);
    },
    _unapplyEvents: function(i) {
      for (var e = 0, s, l, p; e < i.length; e++)
        s = i[e][0], i[e].length === 2 ? (p = o, l = i[e][1]) : i[e].length === 3 && (p = i[e][1], l = i[e][2]), s.off(l, p);
    },
    _buildEvents: function() {
      this.isInput ? this._events = [
        [this.element, {
          focus: t.proxy(this.show, this),
          keyup: t.proxy(function(i) {
            t.inArray(i.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) === -1 && this.update();
          }, this),
          keydown: t.proxy(this.keydown, this)
        }]
      ] : this.component && this.hasInput ? this._events = [
        // For components that are not readonly, allow keyboard nav
        [this.element.find("input"), {
          focus: t.proxy(this.show, this),
          keyup: t.proxy(function(i) {
            t.inArray(i.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) === -1 && this.update();
          }, this),
          keydown: t.proxy(this.keydown, this)
        }],
        [this.component, {
          click: t.proxy(this.show, this)
        }]
      ] : this.element.is("div") ? this.isInline = !0 : this._events = [
        [this.element, {
          click: t.proxy(this.show, this)
        }]
      ], this._events.push(
        // Component: listen for blur on element descendants
        [this.element, "*", {
          blur: t.proxy(function(i) {
            this._focused_from = i.target;
          }, this)
        }],
        // Input: listen for blur on element
        [this.element, {
          blur: t.proxy(function(i) {
            this._focused_from = i.target;
          }, this)
        }]
      ), this._secondaryEvents = [
        [this.picker, {
          click: t.proxy(this.click, this)
        }],
        [t(window), {
          resize: t.proxy(this.place, this)
        }],
        [t(document), {
          "mousedown touchstart": t.proxy(function(i) {
            this.element.is(i.target) || this.element.find(i.target).length || this.picker.is(i.target) || this.picker.find(i.target).length || this.hide();
          }, this)
        }]
      ];
    },
    _attachEvents: function() {
      this._detachEvents(), this._applyEvents(this._events);
    },
    _detachEvents: function() {
      this._unapplyEvents(this._events);
    },
    _attachSecondaryEvents: function() {
      this._detachSecondaryEvents(), this._applyEvents(this._secondaryEvents);
    },
    _detachSecondaryEvents: function() {
      this._unapplyEvents(this._secondaryEvents);
    },
    _trigger: function(i, e) {
      var s = e || this.dates.get(-1), l = this._utc_to_local(s);
      this.element.trigger({
        type: i,
        date: l,
        dates: t.map(this.dates, this._utc_to_local),
        format: t.proxy(function(p, v) {
          arguments.length === 0 ? (p = this.dates.length - 1, v = this.o.format) : typeof p == "string" && (v = p, p = this.dates.length - 1), v = v || this.o.format;
          var y = this.dates.get(p);
          return f.formatDate(y, v, this.o.language);
        }, this)
      });
    },
    show: function() {
      this.isInline || this.picker.appendTo("body"), this.picker.show(), this.place(), this._attachSecondaryEvents(), this._trigger("show");
    },
    hide: function() {
      this.isInline || this.picker.is(":visible") && (this.focusDate = null, this.picker.hide().detach(), this._detachSecondaryEvents(), this.viewMode = this.o.startView, this.showMode(), this.o.forceParse && (this.isInput && this.element.val() || this.hasInput && this.element.find("input").val()) && this.setValue(), this._trigger("hide"));
    },
    remove: function() {
      this.hide(), this._detachEvents(), this._detachSecondaryEvents(), this.picker.remove(), delete this.element.data().datepicker, this.isInput || delete this.element.data().date;
    },
    _utc_to_local: function(i) {
      return i && new Date(i.getTime() + i.getTimezoneOffset() * 6e4);
    },
    _local_to_utc: function(i) {
      return i && new Date(i.getTime() - i.getTimezoneOffset() * 6e4);
    },
    _zero_time: function(i) {
      return i && new Date(i.getFullYear(), i.getMonth(), i.getDate());
    },
    _zero_utc_time: function(i) {
      return i && new Date(Date.UTC(i.getUTCFullYear(), i.getUTCMonth(), i.getUTCDate()));
    },
    getDates: function() {
      return t.map(this.dates, this._utc_to_local);
    },
    getUTCDates: function() {
      return t.map(this.dates, function(i) {
        return new Date(i);
      });
    },
    getDate: function() {
      return this._utc_to_local(this.getUTCDate());
    },
    getUTCDate: function() {
      return new Date(this.dates.get(-1));
    },
    setDates: function() {
      var i = t.isArray(arguments[0]) ? arguments[0] : arguments;
      this.update.apply(this, i), this._trigger("changeDate"), this.setValue();
    },
    setUTCDates: function() {
      var i = t.isArray(arguments[0]) ? arguments[0] : arguments;
      this.update.apply(this, t.map(i, this._utc_to_local)), this._trigger("changeDate"), this.setValue();
    },
    setDate: n("setDates"),
    setUTCDate: n("setUTCDates"),
    setValue: function() {
      var i = this.getFormattedDate();
      this.isInput ? this.element.val(i).change() : this.component && this.element.find("input").val(i).change();
    },
    getFormattedDate: function(i) {
      i === o && (i = this.o.format);
      var e = this.o.language;
      return t.map(this.dates, function(s) {
        return f.formatDate(s, i, e);
      }).join(this.o.multidateSeparator);
    },
    setStartDate: function(i) {
      this._process_options({ startDate: i }), this.update(), this.updateNavArrows();
    },
    setEndDate: function(i) {
      this._process_options({ endDate: i }), this.update(), this.updateNavArrows();
    },
    setDaysOfWeekDisabled: function(i) {
      this._process_options({ daysOfWeekDisabled: i }), this.update(), this.updateNavArrows();
    },
    place: function() {
      if (!this.isInline) {
        var i = this.picker.outerWidth(), e = this.picker.outerHeight(), s = 10, l = D.width(), p = D.height(), v = D.scrollTop(), y = [];
        this.element.parents().each(function() {
          var I = t(this).css("z-index");
          I !== "auto" && I !== 0 && y.push(parseInt(I));
        });
        var T = Math.max.apply(Math, y) + 10, C = this.component ? this.component.parent().offset() : this.element.offset(), x = this.component ? this.component.outerHeight(!0) : this.element.outerHeight(!1), _ = this.component ? this.component.outerWidth(!0) : this.element.outerWidth(!1), S = C.left, F = C.top;
        this.picker.removeClass(
          "datepicker-orient-top datepicker-orient-bottom datepicker-orient-right datepicker-orient-left"
        ), this.o.orientation.x !== "auto" ? (this.picker.addClass("datepicker-orient-" + this.o.orientation.x), this.o.orientation.x === "right" && (S -= i - _)) : (this.picker.addClass("datepicker-orient-left"), C.left < 0 ? S -= C.left - s : C.left + i > l && (S = l - i - s));
        var E = this.o.orientation.y, M, L;
        E === "auto" && (M = -v + C.top - e, L = v + p - (C.top + x + e), Math.max(M, L) === L ? E = "top" : E = "bottom"), this.picker.addClass("datepicker-orient-" + E), E === "top" ? F += x : F -= e + parseInt(this.picker.css("padding-top")), this.picker.css({
          top: F,
          left: S,
          zIndex: T
        });
      }
    },
    _allow_update: !0,
    update: function() {
      if (this._allow_update) {
        var i = this.dates.copy(), e = [], s = !1;
        arguments.length ? (t.each(arguments, t.proxy(function(l, p) {
          p instanceof Date && (p = this._local_to_utc(p)), e.push(p);
        }, this)), s = !0) : (e = this.isInput ? this.element.val() : this.element.data("date") || this.element.find("input").val(), e && this.o.multidate ? e = e.split(this.o.multidateSeparator) : e = [e], delete this.element.data().date), e = t.map(e, t.proxy(function(l) {
          return f.parseDate(l, this.o.format, this.o.language);
        }, this)), e = t.grep(e, t.proxy(function(l) {
          return l < this.o.startDate || l > this.o.endDate || !l;
        }, this), !0), this.dates.replace(e), this.dates.length ? this.viewDate = new Date(this.dates.get(-1)) : this.viewDate < this.o.startDate ? this.viewDate = new Date(this.o.startDate) : this.viewDate > this.o.endDate && (this.viewDate = new Date(this.o.endDate)), s ? this.setValue() : e.length && String(i) !== String(this.dates) && this._trigger("changeDate"), !this.dates.length && i.length && this._trigger("clearDate"), this.fill();
      }
    },
    fillDow: function() {
      var i = this.o.weekStart, e = "<tr>";
      if (this.o.calendarWeeks) {
        var s = '<th class="cw">&nbsp;</th>';
        e += s, this.picker.find(".datepicker-days thead tr:first-child").prepend(s);
      }
      for (; i < this.o.weekStart + 7; )
        e += '<th class="dow">' + d[this.o.language].daysMin[i++ % 7] + "</th>";
      e += "</tr>", this.picker.find(".datepicker-days thead").append(e);
    },
    fillMonths: function() {
      for (var i = "", e = 0; e < 12; )
        i += '<span class="month">' + d[this.o.language].monthsShort[e++] + "</span>";
      this.picker.find(".datepicker-months td").html(i);
    },
    setRange: function(i) {
      !i || !i.length ? delete this.range : this.range = t.map(i, function(e) {
        return e.valueOf();
      }), this.fill();
    },
    getClassNames: function(i) {
      var e = [], s = this.viewDate.getUTCFullYear(), l = this.viewDate.getUTCMonth(), p = /* @__PURE__ */ new Date();
      return i.getUTCFullYear() < s || i.getUTCFullYear() === s && i.getUTCMonth() < l ? e.push("old") : (i.getUTCFullYear() > s || i.getUTCFullYear() === s && i.getUTCMonth() > l) && e.push("new"), this.focusDate && i.valueOf() === this.focusDate.valueOf() && e.push("focused"), this.o.todayHighlight && i.getUTCFullYear() === p.getFullYear() && i.getUTCMonth() === p.getMonth() && i.getUTCDate() === p.getDate() && e.push("today"), this.dates.contains(i) !== -1 && e.push("active"), (i.valueOf() < this.o.startDate || i.valueOf() > this.o.endDate || t.inArray(i.getUTCDay(), this.o.daysOfWeekDisabled) !== -1) && e.push("disabled"), this.range && (i > this.range[0] && i < this.range[this.range.length - 1] && e.push("range"), t.inArray(i.valueOf(), this.range) !== -1 && e.push("selected")), e;
    },
    fill: function() {
      var i = new Date(this.viewDate), e = i.getUTCFullYear(), s = i.getUTCMonth(), l = this.o.startDate !== -1 / 0 ? this.o.startDate.getUTCFullYear() : -1 / 0, p = this.o.startDate !== -1 / 0 ? this.o.startDate.getUTCMonth() : -1 / 0, v = this.o.endDate !== 1 / 0 ? this.o.endDate.getUTCFullYear() : 1 / 0, y = this.o.endDate !== 1 / 0 ? this.o.endDate.getUTCMonth() : 1 / 0, T = d[this.o.language].today || d.en.today || "", C = d[this.o.language].clear || d.en.clear || "", x;
      if (!(isNaN(e) || isNaN(s))) {
        this.picker.find(".datepicker-days thead th.datepicker-switch").text(d[this.o.language].months[s] + " " + e), this.picker.find("tfoot th.today").text(T).toggle(this.o.todayBtn !== !1), this.picker.find("tfoot th.clear").text(C).toggle(this.o.clearBtn !== !1), this.updateNavArrows(), this.fillMonths();
        var _ = k(e, s - 1, 28), S = f.getDaysInMonth(_.getUTCFullYear(), _.getUTCMonth());
        _.setUTCDate(S), _.setUTCDate(S - (_.getUTCDay() - this.o.weekStart + 7) % 7);
        var F = new Date(_);
        F.setUTCDate(F.getUTCDate() + 42), F = F.valueOf();
        for (var E = [], M; _.valueOf() < F; ) {
          if (_.getUTCDay() === this.o.weekStart && (E.push("<tr>"), this.o.calendarWeeks)) {
            var L = +_ + (this.o.weekStart - _.getUTCDay() - 7) % 7 * 864e5;
            E.push('<td class="cw">' + moment(L).format("w") + "</td>");
          }
          if (M = this.getClassNames(_), M.push("day"), this.o.beforeShowDay !== t.noop) {
            var I = this.o.beforeShowDay(this._utc_to_local(_));
            I === o ? I = {} : typeof I == "boolean" ? I = { enabled: I } : typeof I == "string" && (I = { classes: I }), I.enabled === !1 && M.push("disabled"), I.classes && (M = M.concat(I.classes.split(/\s+/))), I.tooltip && (x = I.tooltip);
          }
          M = t.unique(M), E.push('<td class="' + M.join(" ") + '"' + (x ? ' title="' + x + '"' : "") + ">" + _.getUTCDate() + "</td>"), x = null, _.getUTCDay() === this.o.weekEnd && E.push("</tr>"), _.setUTCDate(_.getUTCDate() + 1);
        }
        this.picker.find(".datepicker-days tbody").empty().append(E.join(""));
        var V = this.picker.find(".datepicker-months").find("th:eq(1)").text(e).end().find("span").removeClass("active");
        t.each(this.dates, function(z, Y) {
          Y.getUTCFullYear() === e && V.eq(Y.getUTCMonth()).addClass("active");
        }), (e < l || e > v) && V.addClass("disabled"), e === l && V.slice(0, p).addClass("disabled"), e === v && V.slice(y + 1).addClass("disabled"), E = "", e = parseInt(e / 10, 10) * 10;
        var U = this.picker.find(".datepicker-years").find("th:eq(1)").text(e + "-" + (e + 9)).end().find("td");
        e -= 1;
        for (var N = t.map(this.dates, function(z) {
          return z.getUTCFullYear();
        }), P, W = -1; W < 11; W++)
          P = ["year"], W === -1 ? P.push("old") : W === 10 && P.push("new"), t.inArray(e, N) !== -1 && P.push("active"), (e < l || e > v) && P.push("disabled"), E += '<span class="' + P.join(" ") + '">' + e + "</span>", e += 1;
        U.html(E);
      }
    },
    updateNavArrows: function() {
      if (this._allow_update) {
        var i = new Date(this.viewDate), e = i.getUTCFullYear(), s = i.getUTCMonth();
        switch (this.viewMode) {
          case 0:
            this.o.startDate !== -1 / 0 && e <= this.o.startDate.getUTCFullYear() && s <= this.o.startDate.getUTCMonth() ? this.picker.find(".prev").css({ visibility: "hidden" }) : this.picker.find(".prev").css({ visibility: "visible" }), this.o.endDate !== 1 / 0 && e >= this.o.endDate.getUTCFullYear() && s >= this.o.endDate.getUTCMonth() ? this.picker.find(".next").css({ visibility: "hidden" }) : this.picker.find(".next").css({ visibility: "visible" });
            break;
          case 1:
          case 2:
            this.o.startDate !== -1 / 0 && e <= this.o.startDate.getUTCFullYear() ? this.picker.find(".prev").css({ visibility: "hidden" }) : this.picker.find(".prev").css({ visibility: "visible" }), this.o.endDate !== 1 / 0 && e >= this.o.endDate.getUTCFullYear() ? this.picker.find(".next").css({ visibility: "hidden" }) : this.picker.find(".next").css({ visibility: "visible" });
            break;
        }
      }
    },
    click: function(i) {
      i.preventDefault();
      var e = t(i.target).closest("span, td, th"), s, l, p;
      if (e.length === 1)
        switch (e[0].nodeName.toLowerCase()) {
          case "th":
            switch (e[0].className) {
              case "datepicker-switch":
                this.showMode(1);
                break;
              case "prev":
              case "next":
                var v = f.modes[this.viewMode].navStep * (e[0].className === "prev" ? -1 : 1);
                switch (this.viewMode) {
                  case 0:
                    this.viewDate = this.moveMonth(this.viewDate, v), this._trigger("changeMonth", this.viewDate);
                    break;
                  case 1:
                  case 2:
                    this.viewDate = this.moveYear(this.viewDate, v), this.viewMode === 1 && this._trigger("changeYear", this.viewDate);
                    break;
                }
                this.fill();
                break;
              case "today":
                var y = /* @__PURE__ */ new Date();
                y = k(y.getFullYear(), y.getMonth(), y.getDate(), 0, 0, 0), this.showMode(-2);
                var T = this.o.todayBtn === "linked" ? null : "view";
                this._setDate(y, T);
                break;
              case "clear":
                var C;
                this.isInput ? C = this.element : this.component && (C = this.element.find("input")), C && C.val("").change(), this.update(), this._trigger("changeDate"), this.o.autoclose && this.hide();
                break;
            }
            break;
          case "span":
            e.is(".disabled") || (this.viewDate.setUTCDate(1), e.is(".month") ? (p = 1, l = e.parent().find("span").index(e), s = this.viewDate.getUTCFullYear(), this.viewDate.setUTCMonth(l), this._trigger("changeMonth", this.viewDate), this.o.minViewMode === 1 && this._setDate(k(s, l, p))) : (p = 1, l = 0, s = parseInt(e.text(), 10) || 0, this.viewDate.setUTCFullYear(s), this._trigger("changeYear", this.viewDate), this.o.minViewMode === 2 && this._setDate(k(s, l, p))), this.showMode(-1), this.fill());
            break;
          case "td":
            e.is(".day") && !e.is(".disabled") && (p = parseInt(e.text(), 10) || 1, s = this.viewDate.getUTCFullYear(), l = this.viewDate.getUTCMonth(), e.is(".old") ? l === 0 ? (l = 11, s -= 1) : l -= 1 : e.is(".new") && (l === 11 ? (l = 0, s += 1) : l += 1), this._setDate(k(s, l, p)));
            break;
        }
      this.picker.is(":visible") && this._focused_from && t(this._focused_from).focus(), delete this._focused_from;
    },
    _toggle_multidate: function(i) {
      var e = this.dates.contains(i);
      if (i || this.dates.clear(), this.o.multidate === 1 && e === 0 || (e !== -1 ? this.dates.remove(e) : this.dates.push(i)), typeof this.o.multidate == "number")
        for (; this.dates.length > this.o.multidate; )
          this.dates.remove(0);
    },
    _setDate: function(i, e) {
      (!e || e === "date") && this._toggle_multidate(i && new Date(i)), (!e || e === "view") && (this.viewDate = i && new Date(i)), this.fill(), this.setValue(), this._trigger("changeDate");
      var s;
      this.isInput ? s = this.element : this.component && (s = this.element.find("input")), s && s.change(), this.o.autoclose && (!e || e === "date") && this.hide();
    },
    moveMonth: function(i, e) {
      if (!i)
        return o;
      if (!e)
        return i;
      var s = new Date(i.valueOf()), l = s.getUTCDate(), p = s.getUTCMonth(), v = Math.abs(e), y, T;
      if (e = e > 0 ? 1 : -1, v === 1)
        T = e === -1 ? function() {
          return s.getUTCMonth() === p;
        } : function() {
          return s.getUTCMonth() !== y;
        }, y = p + e, s.setUTCMonth(y), (y < 0 || y > 11) && (y = (y + 12) % 12);
      else {
        for (var C = 0; C < v; C++)
          s = this.moveMonth(s, e);
        y = s.getUTCMonth(), s.setUTCDate(l), T = function() {
          return y !== s.getUTCMonth();
        };
      }
      for (; T(); )
        s.setUTCDate(--l), s.setUTCMonth(y);
      return s;
    },
    moveYear: function(i, e) {
      return this.moveMonth(i, e * 12);
    },
    dateWithinRange: function(i) {
      return i >= this.o.startDate && i <= this.o.endDate;
    },
    keydown: function(i) {
      if (this.picker.is(":not(:visible)")) {
        i.keyCode === 27 && this.show();
        return;
      }
      var e = !1, s, l, p, v = this.focusDate || this.viewDate;
      switch (i.keyCode) {
        case 27:
          this.focusDate ? (this.focusDate = null, this.viewDate = this.dates.get(-1) || this.viewDate, this.fill()) : this.hide(), i.preventDefault();
          break;
        case 37:
        // left
        case 39:
          if (!this.o.keyboardNavigation)
            break;
          s = i.keyCode === 37 ? -1 : 1, i.ctrlKey ? (l = this.moveYear(this.dates.get(-1) || c(), s), p = this.moveYear(v, s), this._trigger("changeYear", this.viewDate)) : i.shiftKey ? (l = this.moveMonth(this.dates.get(-1) || c(), s), p = this.moveMonth(v, s), this._trigger("changeMonth", this.viewDate)) : (l = new Date(this.dates.get(-1) || c()), l.setUTCDate(l.getUTCDate() + s), p = new Date(v), p.setUTCDate(v.getUTCDate() + s)), this.dateWithinRange(l) && (this.focusDate = this.viewDate = p, this.setValue(), this.fill(), i.preventDefault());
          break;
        case 38:
        // up
        case 40:
          if (!this.o.keyboardNavigation)
            break;
          s = i.keyCode === 38 ? -1 : 1, i.ctrlKey ? (l = this.moveYear(this.dates.get(-1) || c(), s), p = this.moveYear(v, s), this._trigger("changeYear", this.viewDate)) : i.shiftKey ? (l = this.moveMonth(this.dates.get(-1) || c(), s), p = this.moveMonth(v, s), this._trigger("changeMonth", this.viewDate)) : (l = new Date(this.dates.get(-1) || c()), l.setUTCDate(l.getUTCDate() + s * 7), p = new Date(v), p.setUTCDate(v.getUTCDate() + s * 7)), this.dateWithinRange(l) && (this.focusDate = this.viewDate = p, this.setValue(), this.fill(), i.preventDefault());
          break;
        case 32:
          break;
        case 13:
          v = this.focusDate || this.dates.get(-1) || this.viewDate, this.o.keyboardNavigation && (this._toggle_multidate(v), e = !0), this.focusDate = null, this.viewDate = this.dates.get(-1) || this.viewDate, this.setValue(), this.fill(), this.picker.is(":visible") && (i.preventDefault(), this.o.autoclose && this.hide());
          break;
        case 9:
          this.focusDate = null, this.viewDate = this.dates.get(-1) || this.viewDate, this.fill(), this.hide();
          break;
      }
      if (e) {
        this.dates.length ? this._trigger("changeDate") : this._trigger("clearDate");
        var y;
        this.isInput ? y = this.element : this.component && (y = this.element.find("input")), y && y.change();
      }
    },
    showMode: function(i) {
      i && (this.viewMode = Math.max(this.o.minViewMode, Math.min(2, this.viewMode + i))), this.picker.find(">div").hide().filter(".datepicker-" + f.modes[this.viewMode].clsName).css("display", "block"), this.updateNavArrows();
    }
  };
  var g = function(i, e) {
    this.element = t(i), this.inputs = t.map(e.inputs, function(s) {
      return s.jquery ? s[0] : s;
    }), delete e.inputs, t(this.inputs).datepicker(e).bind("changeDate", t.proxy(this.dateUpdated, this)), this.pickers = t.map(this.inputs, function(s) {
      return t(s).data("datepicker");
    }), this.updateDates();
  };
  g.prototype = {
    updateDates: function() {
      this.dates = t.map(this.pickers, function(i) {
        return i.getUTCDate();
      }), this.updateRanges();
    },
    updateRanges: function() {
      var i = t.map(this.dates, function(e) {
        return e.valueOf();
      });
      t.each(this.pickers, function(e, s) {
        s.setRange(i);
      });
    },
    dateUpdated: function(i) {
      if (!this.updating) {
        this.updating = !0;
        var e = t(i.target).data("datepicker"), s = e.getUTCDate(), l = t.inArray(i.target, this.inputs), p = this.inputs.length;
        if (l !== -1) {
          if (t.each(this.pickers, function(v, y) {
            y.getUTCDate() || y.setUTCDate(s);
          }), s < this.dates[l])
            for (; l >= 0 && s < this.dates[l]; )
              this.pickers[l--].setUTCDate(s);
          else if (s > this.dates[l])
            for (; l < p && s > this.dates[l]; )
              this.pickers[l++].setUTCDate(s);
          this.updateDates(), delete this.updating;
        }
      }
    },
    remove: function() {
      t.map(this.pickers, function(i) {
        i.remove();
      }), delete this.element.data().datepicker;
    }
  };
  function m(i, e) {
    var s = t(i).data(), l = {}, p, v = new RegExp("^" + e.toLowerCase() + "([A-Z])");
    e = new RegExp("^" + e.toLowerCase());
    function y(C, x) {
      return x.toLowerCase();
    }
    for (var T in s)
      e.test(T) && (p = T.replace(v, y), l[p] = s[T]);
    return l;
  }
  function b(i) {
    var e = {};
    if (!(!d[i] && (i = i.split("-")[0], !d[i]))) {
      var s = d[i];
      return t.each(u, function(l, p) {
        p in s && (e[p] = s[p]);
      }), e;
    }
  }
  var w = t.fn.datepicker;
  t.fn.datepicker = function(i) {
    var e = Array.apply(null, arguments);
    e.shift();
    var s;
    return this.each(function() {
      var l = t(this), p = l.data("datepicker"), v = typeof i == "object" && i;
      if (!p) {
        var y = m(this, "date"), T = t.extend({}, h, y, v), C = b(T.language), x = t.extend({}, h, C, y, v);
        if (l.is(".input-daterange") || x.inputs) {
          var _ = {
            inputs: x.inputs || l.find("input").toArray()
          };
          l.data("datepicker", p = new g(this, t.extend(x, _)));
        } else
          l.data("datepicker", p = new r(this, x));
      }
      if (typeof i == "string" && typeof p[i] == "function" && (s = p[i].apply(p, e), s !== o))
        return !1;
    }), s !== o ? s : this;
  };
  var h = t.fn.datepicker.defaults = {
    autoclose: !1,
    beforeShowDay: t.noop,
    calendarWeeks: !1,
    clearBtn: !1,
    daysOfWeekDisabled: [],
    endDate: 1 / 0,
    forceParse: !0,
    format: "mm/dd/yyyy",
    keyboardNavigation: !0,
    language: "en",
    minViewMode: 0,
    multidate: !1,
    multidateSeparator: ",",
    orientation: "auto",
    rtl: !1,
    startDate: -1 / 0,
    startView: 0,
    todayBtn: !1,
    todayHighlight: !1,
    weekStart: 0
  }, u = t.fn.datepicker.locale_opts = [
    "format",
    "rtl",
    "weekStart"
  ];
  t.fn.datepicker.Constructor = r;
  var d = t.fn.datepicker.dates = {
    en: {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: "Today",
      clear: "Clear"
    }
  }, f = {
    modes: [
      {
        clsName: "days",
        navFnc: "Month",
        navStep: 1
      },
      {
        clsName: "months",
        navFnc: "FullYear",
        navStep: 1
      },
      {
        clsName: "years",
        navFnc: "FullYear",
        navStep: 10
      }
    ],
    isLeapYear: function(i) {
      return i % 4 === 0 && i % 100 !== 0 || i % 400 === 0;
    },
    getDaysInMonth: function(i, e) {
      return [31, f.isLeapYear(i) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][e];
    },
    validParts: /dd?|DD?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\[\u3400-\u9fff-`{-~\t\n\r]+/g,
    parseFormat: function(i) {
      var e = i.replace(this.validParts, "\0").split("\0"), s = i.match(this.validParts);
      if (!e || !e.length || !s || s.length === 0)
        throw new Error("Invalid date format.");
      return { separators: e, parts: s };
    },
    parseDate: function(i, e, s) {
      if (!i)
        return o;
      if (i instanceof Date)
        return i;
      typeof e == "string" && (e = f.parseFormat(e));
      var l = /([\-+]\d+)([dmwy])/, p = i.match(/([\-+]\d+)([dmwy])/g), v, y, T;
      if (/^[\-+]\d+[dmwy]([\s,]+[\-+]\d+[dmwy])*$/.test(i)) {
        for (i = /* @__PURE__ */ new Date(), T = 0; T < p.length; T++)
          switch (v = l.exec(p[T]), y = parseInt(v[1]), v[2]) {
            case "d":
              i.setUTCDate(i.getUTCDate() + y);
              break;
            case "m":
              i = r.prototype.moveMonth.call(r.prototype, i, y);
              break;
            case "w":
              i.setUTCDate(i.getUTCDate() + y * 7);
              break;
            case "y":
              i = r.prototype.moveYear.call(r.prototype, i, y);
              break;
          }
        return k(i.getUTCFullYear(), i.getUTCMonth(), i.getUTCDate(), 0, 0, 0);
      }
      p = i && i.match(this.nonpunctuation) || [], i = /* @__PURE__ */ new Date();
      var C = {}, x = ["yyyy", "yy", "M", "MM", "m", "mm", "d", "dd"], _ = {
        yyyy: function(U, N) {
          return U.setUTCFullYear(N);
        },
        yy: function(U, N) {
          return U.setUTCFullYear(2e3 + N);
        },
        m: function(U, N) {
          if (isNaN(U))
            return U;
          for (N -= 1; N < 0; ) N += 12;
          for (N %= 12, U.setUTCMonth(N); U.getUTCMonth() !== N; )
            U.setUTCDate(U.getUTCDate() - 1);
          return U;
        },
        d: function(U, N) {
          return U.setUTCDate(N);
        }
      }, S, F;
      _.M = _.MM = _.mm = _.m, _.dd = _.d, i = k(i.getFullYear(), i.getMonth(), i.getDate(), 0, 0, 0);
      var E = e.parts.slice();
      p.length !== E.length && (E = t(E).filter(function(U, N) {
        return t.inArray(N, x) !== -1;
      }).toArray());
      function M() {
        var U = this.slice(0, p[T].length), N = p[T].slice(0, U.length);
        return U === N;
      }
      if (p.length === E.length) {
        var L;
        for (T = 0, L = E.length; T < L; T++) {
          if (S = parseInt(p[T], 10), v = E[T], isNaN(S))
            switch (v) {
              case "MM":
                F = t(d[s].months).filter(M), S = t.inArray(F[0], d[s].months) + 1;
                break;
              case "M":
                F = t(d[s].monthsShort).filter(M), S = t.inArray(F[0], d[s].monthsShort) + 1;
                break;
            }
          C[v] = S;
        }
        var I, V;
        for (T = 0; T < x.length; T++)
          V = x[T], V in C && !isNaN(C[V]) && (I = new Date(i), _[V](I, C[V]), isNaN(I) || (i = I));
      }
      return i;
    },
    formatDate: function(i, e, s) {
      if (!i)
        return "";
      typeof e == "string" && (e = f.parseFormat(e));
      var l = {
        d: i.getUTCDate(),
        D: d[s].daysShort[i.getUTCDay()],
        DD: d[s].days[i.getUTCDay()],
        m: i.getUTCMonth() + 1,
        M: d[s].monthsShort[i.getUTCMonth()],
        MM: d[s].months[i.getUTCMonth()],
        yy: i.getUTCFullYear().toString().substring(2),
        yyyy: i.getUTCFullYear()
      };
      l.dd = (l.d < 10 ? "0" : "") + l.d, l.mm = (l.m < 10 ? "0" : "") + l.m, i = [];
      for (var p = t.extend([], e.separators), v = 0, y = e.parts.length; v <= y; v++)
        p.length && i.push(p.shift()), i.push(l[e.parts[v]]);
      return i.join("");
    },
    headTemplate: '<thead><tr><th class="prev">&laquo;</th><th colspan="5" class="datepicker-switch"></th><th class="next">&raquo;</th></tr></thead>',
    contTemplate: '<tbody><tr><td colspan="7"></td></tr></tbody>',
    footTemplate: '<tfoot><tr><th colspan="7" class="today"></th></tr><tr><th colspan="7" class="clear"></th></tr></tfoot>'
  };
  f.template = '<div class="datepicker"><div class="datepicker-days"><table class=" table-condensed">' + f.headTemplate + "<tbody></tbody>" + f.footTemplate + '</table></div><div class="datepicker-months"><table class="table-condensed">' + f.headTemplate + f.contTemplate + f.footTemplate + '</table></div><div class="datepicker-years"><table class="table-condensed">' + f.headTemplate + f.contTemplate + f.footTemplate + "</table></div></div>", t.fn.datepicker.DPGlobal = f, t.fn.datepicker.noConflict = function() {
    return t.fn.datepicker = w, this;
  }, t(document).on(
    "focus.datepicker.data-api click.datepicker.data-api",
    '[data-provide="datepicker"]',
    function(i) {
      var e = t(this);
      e.data("datepicker") || (i.preventDefault(), e.datepicker("show"));
    }
  ), t(function() {
    t('[data-provide="datepicker-inline"]').datepicker();
  });
})(R);
/*!
 * bootstrap-tokenfield
 * https://github.com/Open-Xchange-Frontend/bootstrap-tokenfield
 * Copyright 2013-2016 Sliptree and other contributors; Licensed MIT
 */
(function(t) {
  var o = Date.now || function() {
    return (/* @__PURE__ */ new Date()).getTime();
  };
  function D(n, a, r) {
    var g, m, b, w, h, u = function() {
      var d = o() - w;
      d < a && d >= 0 ? g = setTimeout(u, a - d) : (g = null, h = n.apply(b, m), g || (b = m = null));
    };
    return function() {
      return b = this, m = arguments, w = o(), g || (g = setTimeout(u, a)), h;
    };
  }
  var k = function(n, a) {
    var r = this;
    this.$element = t(n), this.textDirection = this.$element.css("direction"), this.options = t.extend(!0, {}, t.fn.tokenfield.defaults, { tokens: this.$element.val() }, this.$element.data(), a), this._delimiters = typeof this.options.delimiter == "string" ? [this.options.delimiter] : this.options.delimiter, this._triggerKeys = t.map(this._delimiters, function(y) {
      return y.charCodeAt(0);
    }), this._firstDelimiter = this._delimiters[0];
    var g = t.inArray(" ", this._delimiters), m = t.inArray("-", this._delimiters);
    g >= 0 && (this._delimiters[g] = "\\s"), m >= 0 && (delete this._delimiters[m], this._delimiters.unshift("-"));
    var b = ["\\", "$", "[", "{", "^", ".", "|", "?", "*", "+", "(", ")"];
    t.each(this._delimiters, function(y, T) {
      var C = t.inArray(T, b);
      C >= 0 && (r._delimiters[y] = "\\" + T);
    });
    var w = n.style.width, h = this.$element.width(), u = t("body").css("direction") === "rtl" ? "right" : "left", d = { position: this.$element.css("position") };
    d[u] = this.$element.css(u), this.$element.data("original-styles", d).data("original-tabindex", this.$element.prop("tabindex")).attr("aria-hidden", !0).css("position", "absolute").css(u, "-10000px").prop("tabindex", -1), this.$wrapper = t('<div class="tokenfield form-control" />'), this.$element.hasClass("input-lg") && this.$wrapper.addClass("input-lg"), this.$element.hasClass("input-sm") && this.$wrapper.addClass("input-sm"), this.textDirection === "rtl" && this.$wrapper.addClass("rtl");
    var f = this.$element.prop("id") || (/* @__PURE__ */ new Date()).getTime() + "" + Math.floor((1 + Math.random()) * 100);
    this.$input = t('<input type="' + this.options.inputType + '" class="token-input" autocomplete="off" />').appendTo(this.$wrapper).prop("placeholder", this.$element.prop("placeholder")).prop("id", f + "-tokenfield").prop("tabindex", this.$element.data("original-tabindex"));
    var i = t('label[for="' + this.$element.prop("id") + '"]');
    if (i.length && i.prop("for", this.$input.prop("id")), this.$copyHelper = t('<input type="text" tabindex="-1" aria-hidden="true">').css("position", "absolute").css(u, "-10000px").prependTo(this.$wrapper), w ? this.$wrapper.css("width", w) : this.$element.parents(".form-inline").length && this.$wrapper.width(h), (this.$element.prop("disabled") || this.$element.parents("fieldset[disabled]").length) && this.disable(), this.$element.prop("readonly") && this.readonly(), this.$mirror = t('<span style="position:absolute;top:-9999px;left:-9999px;white-space:pre;"/>'), this.$input.css("min-width", this.options.minWidth + "px"), this.$wrapper.insertBefore(this.$element), this.$element.prependTo(this.$wrapper), this.$mirror.appendTo(this.$wrapper), this.update(), this.setTokens(this.options.tokens, !1, !this.$element.val() && this.options.tokens), this.listen(), !t.isEmptyObject(this.options.autocomplete)) {
      var e = this.textDirection === "rtl" ? "right" : "left", s = t.extend({
        minLength: this.options.showAutocompleteOnFocus ? 0 : null,
        position: { my: e + " top", at: e + " bottom", of: this.$wrapper }
      }, this.options.autocomplete);
      this.$input.autocomplete(s);
    }
    if (!t.isEmptyObject(this.options.typeahead)) {
      var l = this.options.typeahead, p = {
        minLength: this.options.showAutocompleteOnFocus ? 0 : null
      }, v = t.isArray(l) ? l : [l, l];
      v[0] = t.extend({}, p, v[0]), this.$input.typeahead.apply(this.$input, v), this.typeahead = !0;
    }
  };
  k.prototype = {
    constructor: k,
    createToken: function(n, a) {
      var r = this;
      if (typeof n == "string" ? n = { value: n, label: n } : n = t.extend({}, n), typeof a > "u" && (a = !0), n.value = t.trim(n.value.toString()), n.label = n.label && n.label.length ? t.trim(n.label) : n.value, !(!n.value.length || !n.label.length || n.label.length < this.options.minLength) && !(this.options.limit && this.getTokens().length >= this.options.limit)) {
        var g = t.Event("tokenfield:createtoken", { attrs: n });
        if (this.$element.trigger(g), !(!g.attrs || g.isDefaultPrevented())) {
          var m = t('<div class="token" tabindex="-1" aria-selected="false" />').append('<span class="token-label" />').append('<a href="#" class="close" tabindex="-1" aria-label="Remove">&times;</a>').data("attrs", n);
          this.$input.hasClass("tt-input") ? this.$input.parent().before(m) : this.$input.before(m), this.$input.css("width", this.options.minWidth + "px");
          var b = m.find(".token-label"), w = m.find(".close");
          return this.options.html ? b.html(n.label) : b.text(n.label), m.on("mousedown", function(h) {
            if (r._disabled || r._readonly) return !1;
            (h.metaKey || h.ctrlKey || h.shiftKey) && (r.preventFocus = !0), r.preventDeactivation = !0;
          }).on("click", function(h) {
            if (r._disabled || r._readonly) return !1;
            if (r.preventDeactivation = !1, h.ctrlKey || h.metaKey)
              return h.preventDefault(), r.toggle(m);
            r.activate(m, h.shiftKey, h.shiftKey);
          }).on("dblclick", function(h) {
            if (r._disabled || r._readonly || !r.options.allowEditing) return !1;
            r.edit(m);
          }).on("keydown", function(h) {
            if (!(h.keyCode !== 67 && h.keyCode !== 88) && (h.ctrlKey || h.metaKey) && t(document.activeElement).hasClass("token")) {
              h.preventDefault();
              var u = r.$wrapper.find(".active");
              r.$copyHelper.val(r.getTokensList(null, null, !0)).focus().select(), document.execCommand("copy"), u.each(function(d) {
                r.activate(t(this), d !== 0, !1, !1);
              }), h.keyCode === 88 && r.remove(h);
            }
          }).on("keydown", t.proxy(this.keydown, this)).on("keyup", t.proxy(this.keyup, this)).on("focus", t.proxy(this.focus, this)).on("blur", t.proxy(this.blur, this)), w.on("click", t.proxy(this.remove, this)), a && this.$element.val(this.getTokensList()).trigger(t.Event("change", { initiator: "tokenfield" })), setTimeout(function() {
            r.maxTokenWidth || (r.maxTokenWidth = r.$wrapper.width() - w.width() - 10), b.css("max-width", r.maxTokenWidth), r.update(), r.$element.trigger(t.Event("tokenfield:createdtoken", {
              attrs: n,
              relatedTarget: m.get(0)
            }));
          }, 0), this.$element.get(0);
        }
      }
    },
    setTokens: function(n, a, r) {
      if (a || this.$wrapper.find(".token").remove(), !!n) {
        typeof r > "u" && (r = !0), typeof n == "string" && (this._delimiters.length ? n = n.split(new RegExp("[" + this._delimiters.join("") + "]")) : n = [n]);
        var g = this;
        return t.each(n, function(m, b) {
          g.createToken(b, r);
        }), this.$element.get(0);
      }
    },
    getTokenData: function(n) {
      var a = n.map(function() {
        var r = t(this);
        return r.data("attrs");
      }).get();
      return a.length === 1 && (a = a[0]), a;
    },
    getTokens: function(n) {
      var a = this, r = [], g = n ? ".active" : "";
      return this.$wrapper.find(".token" + g).each(function() {
        r.push(a.getTokenData(t(this)));
      }), r;
    },
    getTokensList: function(n, a, r) {
      n = n || this._firstDelimiter, a = typeof a < "u" && a !== null ? a : this.options.beautify;
      var g = n + (a && n !== " " ? " " : "");
      return t.map(this.getTokens(r), function(m) {
        return m.value;
      }).join(g);
    },
    getInput: function() {
      return this.$input.val();
    },
    setInput: function(n) {
      this.$input.hasClass("tt-input") ? this.$input.typeahead("val", n) : this.$input.val(n);
    },
    listen: function() {
      var n = this;
      this.$element.on("change.tokenfield", t.proxy(this.change, this)), this.$wrapper.on("mousedown", t.proxy(this.focusInput, this)), this.$input.on("focus", t.proxy(this.focus, this)).on("blur", t.proxy(this.blur, this)).on("paste", t.proxy(this.paste, this)).on("keydown", t.proxy(this.keydown, this)).on("keypress", t.proxy(this.keypress, this)).on("keyup", t.proxy(this.keyup, this)), this.$copyHelper.on("focus", t.proxy(this.focus, this)).on("blur", t.proxy(this.blur, this)).on("keydown", t.proxy(this.keydown, this)).on("keyup", t.proxy(this.keyup, this)), this.$input.on("keypress", t.proxy(this.update, this)).on("keyup", t.proxy(this.update, this)), this.$input.on("autocompletecreate", function() {
        var a = t(this).data("ui-autocomplete").menu.element, r = n.$wrapper.outerWidth() - parseInt(a.css("border-left-width"), 10) - parseInt(a.css("border-right-width"), 10);
        a.css("min-width", r + "px");
      }).on("autocompleteselect", function(a, r) {
        return n.createToken(r.item) && (n.$input.val(""), n.$input.data("edit") && n.unedit(!0)), !1;
      }).on("typeahead:selected typeahead:autocompleted", function(a, r, g) {
        n.createToken(r) && (n.$input.typeahead("val", ""), n.$input.data("edit") && n.unedit(!0));
      }), t(window).on("resize", t.proxy(this.update, this));
    },
    keydown: function(n) {
      if (!this.focused) return;
      var a = this;
      switch (n.keyCode) {
        case 8:
          if (!this.$input.is(document.activeElement)) break;
          this.lastInputValue = this.$input.val();
          break;
        case 37:
          r(this.textDirection === "rtl" ? "next" : "prev");
          break;
        case 38:
          g("prev");
          break;
        case 39:
          r(this.textDirection === "rtl" ? "prev" : "next");
          break;
        case 40:
          g("next");
          break;
        case 65:
          if (this.$input.val().length > 0 || !(n.ctrlKey || n.metaKey)) break;
          this.activateAll(), n.preventDefault();
          break;
        case 9:
        // tab
        case 13:
          if (this.$input.data("ui-autocomplete") && this.$input.data("ui-autocomplete").menu.element.find("li:has(a.ui-state-focus), li.ui-state-focus").length || this.$input.hasClass("tt-input") && this.$wrapper.find(".tt-cursor").length || this.$input.hasClass("tt-input") && this.$wrapper.find(".tt-hint").val() && this.$wrapper.find(".tt-hint").val().length) break;
          if (this.$input.is(document.activeElement) && this.$input.val().length || this.$input.data("edit"))
            return this.createTokensFromInput(n, this.$input.data("edit"));
          if (this.$input.is(document.activeElement) && n.keyCode === 13 && (n.preventDefault(), this.$element.trigger("tokenfield:next")), n.keyCode === 13) {
            if (!this.$copyHelper.is(document.activeElement) || this.$wrapper.find(".token.active").length !== 1 || !a.options.allowEditing) break;
            this.edit(this.$wrapper.find(".token.active"));
          }
      }
      function r(m) {
        if (a.$input.is(document.activeElement)) {
          if (a.$input.val().length > 0) return;
          m += "All";
          var b = a.$input.hasClass("tt-input") ? a.$input.parent()[m](".token:first") : a.$input[m](".token:first");
          if (!b.length) return;
          a.preventInputFocus = !0, a.preventDeactivation = !0, a.activate(b), n.preventDefault();
        } else
          a[m](n.shiftKey), n.preventDefault();
      }
      function g(m) {
        if (n.shiftKey) {
          if (a.$input.is(document.activeElement)) {
            if (a.$input.val().length > 0) return;
            var b = a.$input.hasClass("tt-input") ? a.$input.parent()[m + "All"](".token:first") : a.$input[m + "All"](".token:first");
            if (!b.length) return;
            a.activate(b);
          }
          var w = m === "prev" ? "next" : "prev", h = m === "prev" ? "first" : "last";
          a.$firstActiveToken[w + "All"](".token").each(function() {
            a.deactivate(t(this));
          }), a.activate(a.$wrapper.find(".token:" + h), !0, !0), n.preventDefault();
        }
      }
      this.lastKeyDown = n.keyCode;
    },
    keypress: function(n) {
      if (t.inArray(n.which, this._triggerKeys) !== -1 && this.$input.is(document.activeElement)) {
        var a = this.$input.val(), r = /^"[^"]*$/.test(a);
        return r ? void 0 : (a && this.createTokensFromInput(n), !1);
      }
    },
    keyup: function(n) {
      if (this.preventInputFocus = !1, !!this.focused) {
        switch (n.keyCode) {
          case 8:
            if (this.$input.is(document.activeElement)) {
              if (this.$input.val().length || this.lastInputValue.length && this.lastKeyDown === 8) break;
              this.preventDeactivation = !0;
              var a = this.$input.hasClass("tt-input") ? this.$input.parent().prevAll(".token:first") : this.$input.prevAll(".token:first");
              if (!a.length) break;
              this.activate(a);
            } else
              this.remove(n);
            break;
          case 46:
            this.remove(n, "next");
            break;
        }
        this.lastKeyUp = n.keyCode;
      }
    },
    focus: function(n) {
      this.focused = !0, this.$wrapper.addClass("focus"), t(document.activeElement).hasClass("token") && !this.preventFocus && t(document.activeElement).addClass("active").attr({ tabindex: 0, "aria-selected": !0 }), this.$input.is(document.activeElement) && (this.$wrapper.find(".active").removeClass("active").attr({ tabindex: -1, "aria-selected": !1 }), this.$firstActiveToken = null, this.options.showAutocompleteOnFocus && this.search()), this.preventFocus = !1;
    },
    blur: function(n) {
      this.focused = !1, this.$wrapper.removeClass("focus"), !this.preventDeactivation && !this.$element.is(document.activeElement) && (this.$wrapper.find(".active").removeClass("active").attr({ tabindex: -1, "aria-selected": !1 }), this.$firstActiveToken = null), !this.preventCreateTokens && (this.$input.data("edit") && !this.$input.is(document.activeElement) || this.options.createTokensOnBlur) && this.createTokensFromInput(n), this.preventDeactivation = !1, this.preventCreateTokens = !1;
    },
    paste: function(n) {
      var a = this;
      a.options.allowPasting && setTimeout(function() {
        a.createTokensFromInput(n);
      }, 1);
    },
    change: function(n) {
      n.initiator !== "tokenfield" && this.setTokens(this.$element.val());
    },
    createTokensFromInput: function(n, a) {
      if (!(this.$input.val().length < this.options.minLength)) {
        var r = this.getTokensList();
        return this.setTokens(this.$input.val(), !0), r === this.getTokensList() && this.$input.val().length || (this.setInput(""), this.$input.data("edit") && this.unedit(a)), !1;
      }
    },
    next: function(n) {
      if (n) {
        var a = this.$wrapper.find(".active:first"), r = a && this.$firstActiveToken ? a.index() < this.$firstActiveToken.index() : !1;
        if (r) return this.deactivate(a);
      }
      var g = this.$wrapper.find(".active:last"), m = g.nextAll(".token:first");
      if (!m.length) {
        this.$input.focus();
        return;
      }
      this.activate(m, n);
    },
    prev: function(n) {
      if (n) {
        var a = this.$wrapper.find(".active:last"), r = a && this.$firstActiveToken ? a.index() > this.$firstActiveToken.index() : !1;
        if (r) return this.deactivate(a);
      }
      var g = this.$wrapper.find(".active:first"), m = g.prevAll(".token:first");
      if (m.length || (m = this.$wrapper.find(".token:first")), !m.length && !n) {
        this.$input.focus();
        return;
      }
      this.activate(m, n);
    },
    activate: function(n, a, r, g) {
      if (n) {
        if (typeof g > "u" && (g = !0), r && (a = !0), a || (this.$wrapper.find(".active").removeClass("active").attr({ tabindex: -1, "aria-selected": !1 }), g ? this.$firstActiveToken = n : delete this.$firstActiveToken), r && this.$firstActiveToken) {
          var m = this.$firstActiveToken.index() - 2, b = n.index() - 2, w = this;
          this.$wrapper.find(".token").slice(Math.min(m, b) + 1, Math.max(m, b)).each(function() {
            w.activate(t(this), !0);
          });
        }
        n.addClass("active").attr({ tabindex: 0, "aria-selected": !0 }), this.focusToken(n);
      }
    },
    focusToken: D(function(n) {
      this.preventDeactivation = !0, n.focus();
    }, 10),
    activateAll: function() {
      var n = this;
      this.$wrapper.find(".token").each(function(a) {
        n.activate(t(this), a !== 0, !1, !1);
      });
    },
    deactivate: function(n) {
      n && n.removeClass("active").attr({ tabindex: -1, "aria-selected": !1 });
    },
    toggle: function(n) {
      if (n) {
        var a = !n.hasClass("active");
        n.toggleClass("active").attr({ tabindex: a ? 0 : -1, "aria-selected": a });
      }
    },
    edit: function(n) {
      if (n) {
        var a = n.data("attrs"), r = { attrs: a, relatedTarget: n.get(0) }, g = t.Event("tokenfield:edittoken", r);
        if (this.$element.trigger(g), !g.isDefaultPrevented()) {
          n.find(".token-label").text(a.value);
          var m = n.outerWidth(), b = this.$input.hasClass("tt-input") ? this.$input.parent() : this.$input;
          n.replaceWith(b), this.preventCreateTokens = !0, this.$input.val(a.value).select().data("edit", !0).width(m), this.update(), this.$element.trigger(t.Event("tokenfield:editedtoken", r));
        }
      }
    },
    unedit: function(n) {
      var a = this.$input.hasClass("tt-input") ? this.$input.parent() : this.$input;
      if (a.appendTo(this.$wrapper), this.$input.data("edit", !1), this.$mirror.text(""), this.update(), n) {
        var r = this;
        setTimeout(function() {
          r.$input.focus();
        }, 1);
      }
    },
    remove: function(n, a) {
      if (!(this.$input.is(document.activeElement) || this._disabled || this._readonly)) {
        var r, g = n.type === "click" ? t(n.target).closest(".token") : this.$wrapper.find(".token.active");
        n.type !== "click" && (a || (a = "prev"), this[a](), a === "prev" && (r = g.first().prevAll(".token:first").length === 0));
        var m = { attrs: this.getTokenData(g), relatedTarget: g.get(0) }, b = t.Event("tokenfield:removetoken", m);
        if (this.$element.trigger(b), !b.isDefaultPrevented()) {
          var w = t.Event("tokenfield:removedtoken", m), h = t.Event("change", { initiator: "tokenfield" });
          g.remove(), this.$element.val(this.getTokensList()).trigger(w).trigger(h), (!this.$wrapper.find(".token").length || n.type === "click" || r) && this.$input.focus(), this.$input.css("width", this.options.minWidth + "px"), this.update(), n.preventDefault(), n.stopPropagation();
        }
      }
    },
    /**
     * Update tokenfield dimensions
     */
    update: function(n) {
      var a = this.$input.val(), r = parseInt(this.$input.css("padding-left"), 10), g = parseInt(this.$input.css("padding-right"), 10), m = r + g;
      if (this.$input.data("edit")) {
        if (a || (a = this.$input.prop("placeholder")), a === this.$mirror.text()) return;
        this.$mirror.text(a);
        var b = this.$mirror.width() + 10;
        if (b > this.$wrapper.width())
          return this.$input.width(this.$wrapper.width());
        this.$input.width(b);
      } else {
        this.$input.width(this.options.minWidth);
        var w = this.textDirection === "rtl" ? this.$input.offset().left + this.$input.outerWidth() - this.$wrapper.offset().left - parseInt(this.$wrapper.css("padding-left"), 10) - m - 1 : this.$wrapper.offset().left + this.$wrapper.width() + parseInt(this.$wrapper.css("padding-left"), 10) - this.$input.offset().left - m;
        isNaN(w) ? this.$input.width("100%") : this.$input.width(w);
      }
    },
    focusInput: function(n) {
      if (!(t(n.target).closest(".token").length || t(n.target).closest(".token-input").length || t(n.target).closest(".tt-dropdown-menu").length)) {
        var a = this;
        setTimeout(function() {
          a.$input.focus();
        }, 0);
      }
    },
    search: function() {
      this.$input.data("ui-autocomplete") && this.$input.autocomplete("search");
    },
    disable: function() {
      this.setProperty("disabled", !0);
    },
    enable: function() {
      this.setProperty("disabled", !1);
    },
    readonly: function() {
      this.setProperty("readonly", !0);
    },
    writeable: function() {
      this.setProperty("readonly", !1);
    },
    setProperty: function(n, a) {
      this["_" + n] = a, this.$input.prop(n, a), this.$element.prop(n, a), this.$wrapper[a ? "addClass" : "removeClass"](n);
    },
    destroy: function() {
      this.$element.val(this.getTokensList()), this.$element.css(this.$element.data("original-styles")), this.$element.prop("tabindex", this.$element.data("original-tabindex"));
      var n = t('label[for="' + this.$input.prop("id") + '"]');
      n.length && n.prop("for", this.$element.prop("id")), this.$element.insertBefore(this.$wrapper), this.$element.off(".tokenfield"), this.$element.removeData("original-styles").removeData("original-tabindex").removeData("bs.tokenfield"), this.$wrapper.remove(), this.$mirror.remove();
      var a = this.$element;
      return a;
    }
  };
  var c = t.fn.tokenfield;
  return t.fn.tokenfield = function(n, a) {
    var r, g = [];
    Array.prototype.push.apply(g, arguments);
    var m = this.each(function() {
      var b = t(this), w = b.data("bs.tokenfield"), h = typeof n == "object" && n;
      typeof n == "string" && w && w[n] ? (g.shift(), r = w[n].apply(w, g)) : !w && typeof n != "string" && !a && (b.data("bs.tokenfield", w = new k(this, h)), b.trigger("tokenfield:initialize"));
    });
    return typeof r < "u" ? r : m;
  }, t.fn.tokenfield.defaults = {
    minWidth: 60,
    minLength: 0,
    html: !0,
    allowEditing: !0,
    allowPasting: !0,
    limit: 0,
    autocomplete: {},
    typeahead: {},
    showAutocompleteOnFocus: !1,
    createTokensOnBlur: !1,
    delimiter: ",",
    beautify: !0,
    inputType: "text"
  }, t.fn.tokenfield.Constructor = k, t.fn.tokenfield.noConflict = function() {
    return t.fn.tokenfield = c, this;
  }, k;
})(R);
const ut = {};
export {
  ut as default
};
